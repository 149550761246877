import React from 'react';
import ReactPaginate from 'react-paginate';
import CrispoCard from './CrispoCard';

interface CrispoPaginatorProps {
  currentPage: number;
  onPageChange: (page: number) => any;
  pageCount: number;
}
export const CrispoPaginator = (props: CrispoPaginatorProps) => {
  return (
    <CrispoCard>
      <ReactPaginate
        onPageChange={({ selected }) => {
          props.onPageChange(selected + 1);
        }}
        forcePage={props.currentPage - 1}
        containerClassName='flex justify-center items-center'
        previousLabel='<'
        nextLabel='>'
        previousLinkClassName='px-6'
        nextLinkClassName='px-6'
        breakLabel='...'
        activeClassName='text-crispo font-semibold'
        pageLinkClassName='p-3'
        breakLinkClassName='p-3'
        breakClassName='bg-white hover:bg-gray-200 transition-colors duration-200 text-center py-1'
        pageClassName='py-1 text-center bg-white hover:bg-gray-200 transition-colors duration-200'
        previousClassName='rounded-l py-1 bg-white px-2 border-r hover:bg-gray-200 transition-colors duration-200'
        nextClassName='rounded-r py-1 bg-white px-2 hover:bg-gray-200 transition-colors duration-200 text-center'
        pageCount={props.pageCount}
        pageRangeDisplayed={10}
        marginPagesDisplayed={0}
      />
    </CrispoCard>
  );
};

export interface PageResultsProps {
  options: number[];
  className?: string;
  activeOption: number;
  onOptionClick?: (opt: number) => any;
}
export function PageResults(props: PageResultsProps) {
  return (
    <span className={props.className || ''}>
      Results per page:
      {props.options.map((opt) => {
        return (
          <span
            key={opt}
            className={`ml-2 select-none cursor-pointer ${opt === props.activeOption && 'text-crispo'}`}
            onClick={() => props.onOptionClick && props.onOptionClick(opt)}
          >
            {opt}
          </span>
        );
      })}
    </span>
  );
}
