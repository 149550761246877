import React from 'react';

interface SelectOptionProps {
  onSelectNone?: () => void;
  onSelectAll?: () => void;
  className?: string;
}

export function SelectOptions(props: SelectOptionProps) {
  const selectStyle =
    'mr-1 cursor-pointer select-none text-xs bg-yellow-100 p-1 rounded hover:bg-yellow-200 active:bg-yellow-300 transition-colors';
  return (
    <div className={props.className}>
      <span className={selectStyle} onClick={props.onSelectAll}>
        Select All
      </span>{' '}
      <span className={selectStyle} onClick={props.onSelectNone}>
        Select None
      </span>
    </div>
  );
}
