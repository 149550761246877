import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import { BACKEND_URL } from '../config';

export default class Api {
  private client: AxiosInstance;
  private static readonly TIMEOUT = 1000 * 7;

  constructor(baseURL: string) {
    this.client = axios.create({ baseURL, timeout: Api.TIMEOUT });
  }

  private getHeaders = () => {
    return {
      'content-type': 'application/json',
      token: localStorage.getItem('token'),
    };
  };

  public get = (endpoint: string, options?: AxiosRequestConfig) => {
    return this.client.get(endpoint, {
      headers: this.getHeaders(),
      ...options,
    });
  };

  public post = (endpoint: string, body: object, options?: AxiosRequestConfig) => {
    return this.client.post(endpoint, body, {
      headers: this.getHeaders(),
      ...options,
    });
  };

  public put = (endpoint: string, body: object, options?: AxiosRequestConfig) => {
    return this.client.put(endpoint, body, {
      headers: this.getHeaders(),
      ...options,
    });
  };

  public putFile = (endpoint: string, file: File, options?: AxiosRequestConfig) => {
    return this.client.put(endpoint, file, {
      headers: {
        'content-type': file.type,
      },
      ...options,
    });
  };

  public del = (endpoint: string, options?: AxiosRequestConfig) => {
    return this.client.delete(endpoint, {
      headers: this.getHeaders(),
      ...options,
    });
  };
}

export const useAPI = (baseURL: string) => useMemo(() => new Api(baseURL), [baseURL]);
export const api = new Api(BACKEND_URL);
