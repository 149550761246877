import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { SortTable } from '../../lib/SortTable';
import ContestService, { ContestDto } from './ContestService';

export default function ContestScreen() {
  const [contests, setContests] = useState<ContestDto[]>(ContestService.cache.list);
  const navigate = useNavigate();

  useEffect(() => {
    ContestService.list().then(setContests);
  }, []);

  return (
    <AppShell>
      <Sleeve title='Contests'>
        <SortTable
          onItemClick={(i) => navigate(`/contests/${i._id}`)}
          columns={[
            { title: 'Title', property: 'title' },
            { title: 'Reward', property: (i) => i.reward.title },
            { title: 'Type', property: 'type' },
          ]}
          sortColumn='Title'
          direction={0}
          data={contests.map((c) => ({ id: c._id!, ...c }))}
        />
      </Sleeve>
    </AppShell>
  );
}
