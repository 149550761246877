import { useAPI } from './useAPI';
import { BACKEND_URL } from '../config';
import { useCallback, useContext } from 'react';
import { ChartContext } from '../context/ChartContextProvider';
import { toChartData } from '../util/chart-util';

export function useCharts() {
  const api = useAPI(BACKEND_URL);
  const {
    users,
    setUsers,
    userDays,
    setUserDays,
    scans,
    setScans,
    scanDays,
    setScanDays,
    purchases,
    purchaseDays,
    setPurchaseDays,
    setPurchases,
  } = useContext(ChartContext);

  const fetchUserChartData = useCallback(
    async (days: number) => {
      const res = await api.get(`/charts/users?days=${days}`);
      if (res.ok) {
        const data = await res.json();
        const chartData = toChartData(data, days);
        setUsers(chartData);
        return chartData;
      } else {
        return {};
      }
    },
    [api, setUsers],
  );

  const fetchScanChartData = useCallback(
    async (days: number) => {
      const res = await api.get(`/charts/scans?days=${days}`);
      if (res.ok) {
        const data = await res.json();
        const chartData = toChartData(data, days);
        setScans(chartData);
        return chartData;
      } else {
        return {};
      }
    },
    [api, setScans],
  );

  const fetchPurchaseChartData = useCallback(
    async (days: number) => {
      const res = await api.get(`/charts/purchases?days=${days}`);
      if (res.ok) {
        const data = await res.json();
        const chartData = toChartData(data, days);
        setPurchases(chartData);
        return chartData;
      } else {
        return {};
      }
    },
    [api, setPurchases],
  );

  return {
    fetchUserChartData,
    fetchPurchaseChartData,
    fetchScanChartData,
    users,
    userDays,
    setUserDays,
    scans,
    scanDays,
    setScanDays,
    purchases,
    purchaseDays,
    setPurchaseDays,
  };
}
