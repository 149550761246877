import { useNavigate, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { StatusDto } from './StatusDto';
import StatusForm from './StatusForm';
import StatusService from './StatusService';

export default function StatusEditScreen() {
  const params = useParams();
  const nav = useNavigate();
  const [status, setStatus] = useState<StatusDto>();

  useEffect(() => {
    StatusService.find(params.id).then(setStatus);
  }, [params.id]);

  function onSubmit(status: StatusDto) {
    StatusService.update(params.id, status).then(() => nav('.'));
  }

  return (
    <AppShell>
      <Sleeve back title='Status Edit'>
        {status && <StatusForm status={status} onSubmit={onSubmit} />}
      </Sleeve>
    </AppShell>
  );
}
