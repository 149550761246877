import axios from 'axios';
import join from 'url-join';
import { useMemo, useCallback } from 'react';
import { BACKEND_URL } from '../config';

export default {
  url: BACKEND_URL,
  client: axios.create({ baseURL: BACKEND_URL }),
  getHeaders() {
    const token = localStorage.getItem('token');
    const headers = {
      'content-type': 'application/json',
    };
    return token ? { ...headers, token: token } : headers;
  },
  async get(endpoint: string, options?: RequestInit) {
    const res = await fetch(join(this.url, endpoint), {
      ...options,
      headers: this.getHeaders(),
    });
    return res;
  },

  async putFile(endpoint: string, file: File) {
    const res = await axios.put(endpoint, file, {
      headers: {
        'content-type': file.type,
      },
    });
    return res;
  },

  async put(endpoint: string, body: any) {
    const res = await fetch(join(this.url, endpoint), {
      method: 'put',
      headers: this.getHeaders(),
      body: JSON.stringify(body),
    });
    return res;
  },

  async post(endpoint: string, body: any) {
    const res = await fetch(join(this.url, endpoint), {
      method: 'post',
      headers: this.getHeaders(),
      body: JSON.stringify(body),
    });
    return res;
  },

  async postFormData(endpoint: string, data: FormData) {
    let headers: any = this.getHeaders();
    delete headers['content-type'];
    const res = await fetch(join(this.url, endpoint), {
      method: 'post',
      headers: headers,
      body: data,
    });
    return res;
  },

  async patch(endpoint: string, body: any) {
    const res = await fetch(join(this.url, endpoint), {
      method: 'PATCH',
      headers: this.getHeaders(),
      body: JSON.stringify(body),
    });
    return res;
  },

  async del(endpoint: string) {
    const res = await fetch(join(this.url, endpoint), {
      method: 'delete',
      headers: this.getHeaders(),
    });
    return res;
  },
};

export function useAPI(url: string) {
  const getHeaders = useCallback(() => {
    const token = localStorage.getItem('token');
    const headers = {
      'content-type': 'application/json',
    };
    return token ? { ...headers, token: token } : headers;
  }, []);

  const get = useCallback(
    async (endpoint: string, options?: RequestInit) => {
      const res = await fetch(join(url, endpoint), {
        method: 'GET',
        ...options,
        headers: getHeaders(),
      });
      return res;
    },
    [url, getHeaders],
  );

  const putFile = useCallback(
    async (endpoint: string, file: File) => {
      const res = await axios.put(join(url, endpoint), file, {
        headers: {
          'content-type': file.type,
        },
      });
      return res;
    },
    [url],
  );

  const post = useCallback(
    async (endpoint: string, body: any) => {
      const res = await fetch(join(url, endpoint), {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(body),
      });
      return res;
    },
    [url, getHeaders],
  );

  const patch = useCallback(
    async (endpoint: string, body: any) => {
      const res = await fetch(join(url, endpoint), {
        method: 'PATCH',
        headers: getHeaders(),
        body: JSON.stringify(body),
      });
      return res;
    },
    [url, getHeaders],
  );

  const postFormData = useCallback(
    async (endpoint: string, data: FormData) => {
      let headers: any = getHeaders();
      delete headers['content-type'];
      const res = await fetch(join(url, endpoint), {
        method: 'post',
        headers: headers,
        body: data,
      });
      return res;
    },
    [url, getHeaders],
  );

  const put = useCallback(
    async (endpoint: string, body: any) => {
      const res = await fetch(join(url, endpoint), {
        method: 'put',
        headers: getHeaders(),
        body: JSON.stringify(body),
      });
      return res;
    },
    [url, getHeaders],
  );

  const del = useCallback(
    async (endpoint: string) => {
      const res = await fetch(join(url, endpoint), {
        method: 'delete',
        headers: getHeaders(),
      });
      return res;
    },
    [url, getHeaders],
  );

  return useMemo(() => {
    return {
      get,
      post,
      put,
      del,
      patch,
      putFile,
      postFormData,
    };
  }, [get, patch, post, put, del, putFile, postFormData]);
}
