import api from '../../../hooks/useAPI';
import { IProduct } from '../Reedeemed/RedeemedService';

export interface IProductGroup {
  _id: string;
  title: string;
  products: IProduct[];
}

export default {
  url: '/product-group',
  cache: { list: [] },
  async create(product: { title: string; products: string[] }) {
    return api.post(this.url, product).then((res) => res.json());
  },

  async list() {
    return api
      .get(this.url)
      .then((res) => res.json())
      .then((data) => {
        this.cache.list = data;
        return data;
      });
  },

  async find(id: string) {
    return api.get(`${this.url}/${id}`).then((res) => res.json());
  },

  async remove(id: string) {
    return api.del(`${this.url}/${id}`).then((res) => res.ok);
  },

  async update(id: string, update: any) {
    return api.patch(`${this.url}/${id}`, update).then((res) => res.json());
  },
};
