import api from '../../../hooks/useAPI';

export interface SettingsDto {
  _id: string;
  key: string;
  value: any;
  type: string;
}

const SettingsService = {
  async list(): Promise<SettingsDto[]> {
    return api.get('/settings').then((res) => res.json());
  },

  async update(key: string, value: any, type: string) {
    return api.put(`/settings/${key}`, { value, type }).then((res) => res.json());
  },
};

export default SettingsService;
