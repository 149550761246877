import { useNavigate, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { RewardDto, RewardRawDto } from './RewardDto';
import RewardForm from './RewardForm';
import RewardService from './RewardService';

export default function RewardEditScreen() {
  const [reward, setReward] = useState<RewardDto>();
  const params = useParams();
  const nav = useNavigate();

  useEffect(() => {
    RewardService.find(params.id).then(setReward);
  }, [params.id]);

  function onSubmit(dto: RewardRawDto) {
    RewardService.update(params.id, dto).then(() => nav('.'));
  }

  return (
    <AppShell>
      <Sleeve back title='Reward Edit'>
        {reward && <RewardForm reward={reward} onSubmit={onSubmit} />}
      </Sleeve>
    </AppShell>
  );
}
