import { useNavigate, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { CrispoButton } from '../../lib/CrispoButton';
import { Sleeve } from '../../lib/Sleeve';
import ProductGroupForm from './ProductGroupForm';
import ProductGroupService, { IProductGroup } from './ProductGroupService';

export default function ProductGroupEditScreen() {
  const params = useParams();
  const [productGroup, setProductGroup] = useState<IProductGroup>();
  const [isLoading, setIsLoading] = useState(false);
  const id = params.id;
  const nav = useNavigate();

  useEffect(() => {
    ProductGroupService.find(id).then(setProductGroup);
  }, [id]);

  function onSubmit(title: string, products: string[]) {
    setIsLoading(true);
    ProductGroupService.update(id, { title, products })
      .then(() => nav('/product-groups'))
      .finally(() => setIsLoading(false));
  }

  function onDelete() {
    if (window.confirm('Are you sure?')) {
      ProductGroupService.remove(params.id).then(() => nav('/product-groups'));
    }
  }

  return (
    <AppShell>
      <Sleeve
        back
        title='Product Group Edit'
        action={
          <div className='my-auto ml-3'>
            <CrispoButton danger onClick={onDelete} className='mb-3 mr-auto' title='Delete' />
          </div>
        }
      >
        <ProductGroupForm isLoading={isLoading} productGroup={productGroup} onSubmit={onSubmit} />
      </Sleeve>
    </AppShell>
  );
}
