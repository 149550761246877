import { IUser } from '../../../dto/IUser';
import api from '../../../hooks/useAPI';
import { IProductGroup } from '../ProductGroup/ProductGroupService';
import { Id, RewardDto } from '../Rewards/RewardDto';

export default {
  url: '/transaction',
  async list(): Promise<IRedemption[]> {
    return api.get(this.url).then((res) => res.json());
  },

  async find(id: string): Promise<IRedemption> {
    return api.get(this.url + '/' + id).then((res) => res.json());
  },

  async updateSent(id: string, sent: boolean) {
    return api.patch(this.url + '/' + id, { sent }).then((res) => res.json());
  },
};

export interface IRedemption {
  _id: string;
  type: string;
  date: Date;
  user: IUser;
  order: {
    products: IProductChoice[];
    selections: IProductSelectionChoice[];
  };
  products: IProduct[];
  reward: RewardDto;
  sent: boolean;
  code: string;
}

export interface IProductSelectionChoice {
  selectionId: Id;
  productSelection: IProductGroup;
  product: IProductChoice;
}

export interface IProduct {
  _id?: string;
  title: string;
  imageUrl: string;
  options: string[];
}

export interface IProductChoice {
  productId?: string;
  product: IProduct;
  option?: string;
}
