import React from 'react';
import { CrispoButton } from '../../lib/CrispoButton';
import { SubmissionDto } from './ContestService';

export interface SubmissionItemProps {
  submission: SubmissionDto;
  onClick?: () => void;
}

export default function SubmissionItem({ submission, onClick }: SubmissionItemProps) {
  return (
    <div className='p-3 border hover:bg-gray-200'>
      <img
        className='object-cover bg-gray-100'
        src={`https://crispo-canada-rewards-images.s3.amazonaws.com/${submission.key}`}
        alt='submission_picture'
      />
      <p className='my-3 text-crispo'>{submission.user.username}</p>
      {onClick && (
        <div className='flex justify-center'>
          <CrispoButton block title='Select' onClick={onClick} />
        </div>
      )}
    </div>
  );
}
