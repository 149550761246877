import React from 'react';
import { Link } from '@reach/router';
import clsx from 'clsx';
import { IconType } from 'react-icons';

interface SideNavLinkProps {
  to: string;
  text: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  icon?: IconType;
}
export const SideNavLink: React.FC<SideNavLinkProps> = (props) => {
  return (
    <Link
      onClick={props.onClick}
      to={props.to}
      getProps={(p) => {
        return {
          className: clsx(
            p.isPartiallyCurrent
              ? 'bg-gray-200 rounded-lg text-crispo'
              : 'bg-white text-crispo hover:bg-gray-100 hover:text-crispo-dark',
            'nav-link mt-1 overflow-ellipsis group rounded-lg flex items-center px-2 py-2 text-sm leading-5 font-medium transition duration-200',
          ),
        };
      }}
    >
      {props.icon && <props.icon size='22' className='mr-3' />}
      {props.children}
      <span className='text-sm text-cool-gray-600'>{props.text}</span>
    </Link>
  );
};
