import React from 'react';
import Loader from 'react-spinners/ClipLoader';
import clsx from 'clsx';

type ReactButton = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const AppButton: React.FC<ReactButton> = ({ children, className, ...rest }) => (
  <button
    {...rest}
    className={`shadow p2 font-bold rounded focus:shadow-outline-blue transition-all ease-in-out duration-200 ${className}`}
  >
    {children}
  </button>
);

interface Props extends ReactButton {
  danger?: boolean;
  secondary?: boolean;
  block?: boolean;
  title: string;
  isLoading?: boolean;
  sm?: boolean;
  color?: string;
  active?: boolean;
  box?: boolean;
}
export function CrispoButton(props: Props) {
  const { box, block, active, danger, secondary, title, className, sm, isLoading, ...rest } = props;
  return (
    <button
      className={clsx(
        danger
          ? active
            ? 'bg-red-500 text-white'
            : 'bg-red-400 hover:bg-red-500 text-white'
          : secondary
          ? active
            ? 'bg-gray-300 text-cool-gray-700'
            : 'bg-gray-100 hover:bg-gray-200 text-cool-gray-600'
          : 'bg-crispo hover:bg-crispo-dark text-white',
        block && 'w-full',
        sm ? 'h-8' : 'h-12',
        !box && 'rounded-md',
        'flex justify-center items-center p-2 text-sm outline-none focus:outline-none transition ease-in-out duration-200',
        className,
      )}
      {...rest}
    >
      {title}
      {isLoading && <Loader size={16} css={'margin-left: 1rem'} color={secondary ? 'black' : 'white'} loading={true} />}
    </button>
  );
}
