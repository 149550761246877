import React, { useEffect, useState } from 'react';
import ProductService from '../../../hooks/ProductService';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput } from '../../lib/CrispoInput';
import CrispoLabel from '../../lib/CrispoLabel';
import ItemSelection from '../../lib/ItemSelection';
import { SelectionItem } from '../../lib/ItemSelectionList';
import { IProduct } from '../Reedeemed/RedeemedService';
import { IProductGroup } from './ProductGroupService';

interface ProductGroupFormProps {
  productGroup?: IProductGroup;
  isLoading?: boolean;
  onSubmit(title: string, products: string[]): void;
}

export default function ProductGroupForm(props: ProductGroupFormProps) {
  const [title, setTitle] = useState(props.productGroup?.title || '');
  const [products, setProducts] = useState(props.productGroup?.products || []);
  const [availableProducts, setAvailableProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    setTitle(props.productGroup?.title || '');
    setProducts(props.productGroup?.products || []);
  }, [props.productGroup]);

  useEffect(() => {
    ProductService.list().then(setAvailableProducts);
  }, []);

  function onAddProducts(items: SelectionItem[]) {
    setProducts(products.concat(items.map((i) => i.item)));
  }

  function onRemoveProducts(items: SelectionItem[]) {
    setProducts(products.filter((p) => !items.find((i) => i._id === p._id)));
  }

  function onTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTitle(e.target.value);
  }

  function onSubmit() {
    props.onSubmit(
      title,
      products.filter((p) => !!p._id).map((p) => p._id!),
    );
  }

  const leftItems = availableProducts
    .filter((ap) => !products.find((p) => p._id === ap._id))
    .map((p) => ({ _id: p._id!, text: p.title, item: p }));

  const rightItems = products.map((p) => ({ _id: p._id!, text: p.title, item: p }));

  return (
    <CrispoCard>
      <form className='' onSubmit={(e) => e.preventDefault()}>
        <div className='mb-3'>
          <CrispoInput
            label='Product Group Title'
            placeholder='Product Group Title'
            name='title'
            onChange={onTitleChange}
            value={title}
          />
        </div>
        <div className='my-3'></div>
        <CrispoLabel>Products</CrispoLabel>
        <ItemSelection
          leftTitle='Available Products'
          rightTitle='Products'
          onAddItems={onAddProducts}
          onRemoveItems={onRemoveProducts}
          leftItems={leftItems}
          rightItems={rightItems}
        />

        <CrispoButton title='Submit' isLoading={props.isLoading} onClick={onSubmit} />
      </form>
    </CrispoCard>
  );
}
