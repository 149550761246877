import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { RouteComponentProps } from '@reach/router';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { CrispoButton } from '../../lib/CrispoButton';
import { IQr } from '../../../dto/IQr';
import qs from 'query-string';
import QRCode from 'qrcode.react';
import { api } from '../../../services/api';
import { BeatLoader } from 'react-spinners';
import { CrispoPaginator, PageResults } from '../../lib/CrispoPaginator';
import { useNavigate } from '@reach/router';
import { ChangeEvent } from 'react';

export function QrView(props: RouteComponentProps) {
  const [qrs, setQrs] = useState<IQr[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [generateAmount, setGenerateAmount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalQrs, setTotalQrs] = useState(100);
  const totalPages = useMemo(() => Math.ceil(totalQrs / limit), [totalQrs, limit]);
  const [printSettings, setPrintSettings] = useState({
    printStrategy: 'display',
    printOne: 0,
    printRecent: 0,
    printStart: 0,
    printEnd: 0,
    printTemplate: 'template',
  });
  const { printStrategy, printOne, printRecent, printStart, printEnd, printTemplate } = printSettings;
  const nav = useNavigate();

  async function handleGenerate() {
    const response = await api.post(`/qr`, { amount: generateAmount });
    const ok = response.status === 201;
    if (!ok) {
      alert('Unable to generate QR codes');
    }
    setGenerateAmount(0);
    fetchCodes();
  }

  type FormChangeEventHandler<T extends HTMLInputElement | HTMLSelectElement> = (event: ChangeEvent<T>) => void;

  const monitorPrintSettings: FormChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
    const field: string = e.target.name;
    const value: string = e.target.value;
    const type: string = e.target.type;
    switch (type) {
      case 'radio':
        setPrintSettings({
          printStrategy: value,
          printOne: 0,
          printRecent: 0,
          printStart: 0,
          printEnd: 0,
          printTemplate: printTemplate,
        })
        break;
      case 'number':
        let setPrint = {
          ...printSettings,
          // number inputs have a strategy data property so printStrategy can automatically change if a numerical input changes
          printStrategy: e.target.dataset.strategy!,
          printOne: 0,
          printRecent: 0
        }
        // if the strategy is not range, clear both the start and end
        if (e.target.dataset.strategy !== 'range') {
          setPrint.printStart = 0;
          setPrint.printEnd = 0;
        }
        setPrintSettings({...setPrint, [field]: parseInt(value) >= 0 ? parseInt(value) : 0})
        break;
      case 'select-one':
        setPrintSettings({
          ...printSettings,
          printTemplate: value
        })
        break;
    }
  };

  const fetchCodes = useCallback(async () => {
    setIsLoading(true);
    const skipOnPage = (page - 1) * limit;
    const query = qs.stringify({ limit, skip: skipOnPage });
    try {
      const res = await api.get(`/qr?${query}`);
      setQrs(res.data.codes);
      setTotalQrs(res.data.total);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }, [limit, page]);

  useEffect(() => {
    fetchCodes();
  }, [fetchCodes]);

  const handleGenerateChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value: number = parseInt(e.target.value);
    if (value < 0) return;
    setGenerateAmount(value);
  };

  const printSlips = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let navLimit: number = 10;
    let navSkip: number = 0;
    switch (printStrategy) {
      case 'display':
        navLimit = limit;
        navSkip = (page - 1) * limit;
        break;
      case 'one':
        navLimit = 1;
        navSkip = printOne - 1;
        break;
      case 'recent':
        navLimit = printRecent;
        navSkip = 0;
        break;
      case 'range':
        navLimit = printStart === 0 ? (printEnd - printStart) : (printEnd - printStart) + 1;
        navSkip = printStart === 0 ? 0 : printStart - 1;
        break;
    }
    const query: string = qs.stringify({ limit: navLimit, skip: navSkip, template: printTemplate });
    nav(`/qr/print-preview?${query}`);
  }
 
  return (
    <AppShell>
      <Sleeve title='QR Codes'>
        {isLoading ? (
          <div className='flex items-center justify-center w-full h-full'>
            <BeatLoader size='16px' color='#0166ae' />
          </div>
        ) : (
          <>
            <div className='p-3 m-2 bg-white rounded-lg shadow w-max'>
              <h1 className='mb-1'>Generate QR:</h1>
              <div className='flex items-center mb-3 mx-3'>
                  <label htmlFor='generate' className='block'>
                    Amount
                  </label>
                  <input
                    id='generate'
                    placeholder='Amount to generate'
                    className='flex h-8 mx-3 px-2 border rounded-md form-input'
                    type='number'
                    value={generateAmount}
                    onChange={handleGenerateChange}
                  />
                <CrispoButton
                  title='Generate'
                  className={`${generateAmount === 0 && 'opacity-50'}`}
                  onClick={handleGenerate}
                  disabled={generateAmount === 0}
                />
              </div>
            </div>

            <div className='p-3 m-2 mb-5 bg-white rounded-lg shadow w-max'>
              <div className='items-end mb-3 mx-3'>
                  <div className='block'>
                    Print:
                  </div>
                  <form className='flex flex-wrap' onSubmit={printSlips}>
                    <div className='m-2 flex items-center'>
                      <div className='h-8 flex items-center'>
                        <input type='radio' id='printDisplayed' name='printStrategy' className='mr-2' value='display' checked={printStrategy === 'display'} onChange={monitorPrintSettings} />
                        <label htmlFor='printDisplayed' className='flex items-center text-sm'>
                          Displayed
                        </label>
                      </div>
                    </div>
                    <div className='m-2 flex items-center'>
                      <input type='radio' id='printOne' name='printStrategy' className='mr-2' value='one' checked={printStrategy === 'one'} onChange={monitorPrintSettings} />
                      <label htmlFor='printOne' className='flex items-center text-sm'>
                        One
                        <input
                          data-strategy='one'
                          name='printOne'
                          placeholder='10'
                          className='flex h-10 w-16 px-2 ml-2 border rounded-md form-input'
                          type='number'
                          value={printOne}
                          onChange={monitorPrintSettings}
                        />
                      </label>
                    </div>
                    <div className='m-2 flex items-center'>
                      <input type='radio' id='printRecent' name='printStrategy' className='mr-2' value='recent' checked={printStrategy === 'recent'} onChange={monitorPrintSettings} />
                      <label htmlFor='printRecent' className='flex items-center text-sm'>
                        Recent
                        <input
                          data-strategy='recent'
                          name='printRecent'
                          placeholder='10'
                          className='flex h-10 w-16 px-2 ml-2 border rounded-md form-input'
                          type='number'
                          value={printRecent}
                          onChange={monitorPrintSettings}
                        />
                      </label>
                    </div>
                    <div className='m-2 flex items-center'>
                      <input type='radio' id='printRange' name='printStrategy' className='mr-2' value='range' checked={printStrategy === 'range'} onChange={monitorPrintSettings} />
                      <label htmlFor='printRange' className='flex items-center text-sm'>
                        Range
                        <input
                          data-strategy='range'
                          name='printStart'
                          placeholder='10'
                          className='flex h-10 w-16 px-2 ml-2 border rounded-md form-input'
                          type='number'
                          value={printStart}
                          onChange={monitorPrintSettings}
                        />
                        <input
                          data-strategy='range'
                          name='printEnd'
                          placeholder='10'
                          className='flex h-10 w-16 px-2 ml-2 border rounded-md form-input'
                          type='number'
                          value={printEnd}
                          onChange={monitorPrintSettings}
                        />
                      </label>
                    </div>
                    <select
                      name='printTemplate'
                      className='h-10 w-36 my-2 mx-3 px-2 border rounded-md form-input text-sm'
                      value={printTemplate}
                      onChange={monitorPrintSettings}
                    >
                      <option disabled value='template' className='bg-gray-300 text-white'>Template Size</option>
                      <option value='small'>Small 5"x2"</option>
                      <option value='large'>Large 6"x4"</option>
                    </select>
                    <CrispoButton
                      title='Print Slips'
                      className={`${((printStrategy !== 'display' && printOne === 0 && printRecent === 0 && (printEnd - printStart <= 0)) || printTemplate === 'template') && 'opacity-50'}`}
                      disabled={(printStrategy !== 'display' && printOne === 0 && printRecent === 0 && (printEnd - printStart <= 0)) || printTemplate === 'template'}
                    />
                  </form>
                </div>
            </div>

            <div className='flex items-end mb-3'>
              {/* Paginator */}
              <PageResults
                className='flex items-center justify-end flex-1 text-sm'
                options={[10, 25, 50, 100]}
                activeOption={limit || 10}
                onOptionClick={setLimit}
              />
            </div>

            <div className='grid justify-around grid-flow-row xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2'>
              {qrs?.map((qr: IQr) => {
                return (
                  <div className='p-3 m-2 bg-white rounded-lg shadow w-max' key={qr._id}>
                    <div className='text-center'>
                      <h1 className='mb-1 text-xs'>{qr.code}</h1>
                      <QRCode value={qr.code} size={120} className='mx-auto my-2' />
                    </div>
                  </div>
                );
              })}
            </div>
            
            <div className='items-center justify-center mt-5 mb-5 xl:flex'>
              <span className='flex-1 text-xs text-left'>
                Showing {limit * page - limit + 1}-{Math.min(limit * page, totalQrs)} out of {totalQrs} results
              </span>
              {totalPages > 1 && <CrispoPaginator pageCount={totalPages} currentPage={page} onPageChange={setPage} />}
              <span className='flex-1' />
            </div>
          </>
        )}
      </Sleeve>
    </AppShell>
  );
}
