import { dateToObject, daysFromNow, dateObjectsAreEqual } from './date-util';

export function toChartData(data: { _id: string; count: number }[], days: number) {
  return daysFromNow(days).map((el: any) => {
    const found = data.find((x) => dateObjectsAreEqual(dateToObject(new Date(x._id)), dateToObject(el.x)));
    el.y = found ? found.count : 0;
    const dateString: string = el.x.toDateString();
    el.x = dateString.substring(4, dateString.length - 4).trim();
    return el;
  });
}
