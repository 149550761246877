import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import ProductService from '../../../hooks/ProductService';
import { AppShell } from '../../lib/AppShell';
import { CrispoButton } from '../../lib/CrispoButton';
import { Sleeve } from '../../lib/Sleeve';
import { IProduct } from '../Reedeemed/RedeemedService';
import ProductListItem from './ProductListItem';

export default function ProductScreen() {
  const [products, setProducts] = useState(ProductService.cache.list);
  const nav = useNavigate();

  useEffect(() => {
    ProductService.list().then(setProducts);
  }, []);

  function onProductNew() {
    nav('/products/new');
  }

  function onProductEdit(product: IProduct) {
    nav(`/products/${product._id}/edit`);
  }

  function onProductDelete(id: string) {
    if (!id) return;
    if (window.confirm('Are you sure?')) {
      ProductService.remove(id).then(() => ProductService.list().then(setProducts));
    }
  }

  return (
    <AppShell>
      <Sleeve
        title='Products'
        action={
          <div className='ml-3'>
            <CrispoButton secondary onClick={onProductNew} title='New' />
          </div>
        }
      >
        <div className='grid grid-cols-3 gap-3'>
          {products.map((p: IProduct) => (
            <ProductListItem key={p._id} onEdit={onProductEdit} product={p} onDelete={onProductDelete} />
          ))}
        </div>
      </Sleeve>
    </AppShell>
  );
}
