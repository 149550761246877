import React from 'react';
import { useParams } from '@reach/router';
import { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import ContestService, { ContestDto, ContestRawDto, ContestType, SubmissionDto, WinnerDto } from './ContestService';
import ContestForm from './ContestForm';
import CrispoCard from '../../lib/CrispoCard';
import DrawForm from './DrawForm';
import { SortTable } from '../../lib/SortTable';
import PhotoSubmissionGrid from './PhotoSubmissionGrid';

export default function ContestDetailScreen() {
  const [contest, setContest] = useState<ContestDto>();
  const [winners, setWinners] = useState<WinnerDto[]>();
  const params = useParams();

  useEffect(() => {
    ContestService.find(params.id).then(setContest);
    ContestService.getWinners(params.id).then(setWinners);
  }, [params.id]);

  function onSubmit(contest: ContestRawDto) {
    ContestService.update(params.id, contest).then(() => alert('Contest successfully saved'));
  }

  function selectPhoto(s: SubmissionDto) {
    if (!window.confirm(`Are you sure you want to select ${s.user.username} as the winner?`)) return;
    ContestService.pickWinner(params.id, s.user._id).then(() => ContestService.getWinners(params.id).then(setWinners));
  }

  function draw(from: Date, to: Date) {
    ContestService.draw(params.id, from, to)
      .then((user) => {
        if (!window.confirm(`Are you sure you want to select ${user.username} as the winner?`)) return;
        ContestService.pickWinner(params.id, user._id).then(() =>
          ContestService.getWinners(params.id).then(setWinners),
        );
      })
      .catch(() => alert('No winner found'));
  }

  function getActionComponent() {
    switch (contest?.type) {
      case ContestType.ScanDraw:
      case ContestType.TicketDraw:
        return <DrawForm onSubmit={draw} />;
      case ContestType.PhotoSelection:
        return contest && <PhotoSubmissionGrid contest={contest._id!} onItemSelect={selectPhoto} />;
    }
  }

  return (
    <AppShell>
      <Sleeve back title='Contest Details'>
        <CrispoCard>
          <Heading text='Contest' />
          {contest && <ContestForm contest={contest} onSubmit={onSubmit} />}
          <div className='mb-6'></div>
          <Heading text='Select Winner' />
          {getActionComponent()}
          <div className='mb-6'></div>
          <Heading text='Winners' />
          {winners && winners.length > 0 && (
            <SortTable
              sortColumn='Date'
              data={
                winners?.map((winner) => ({
                  id: winner.date,
                  username: winner.username,
                  date: new Date(winner.date).toLocaleString(),
                })) || []
              }
              direction={0}
              columns={[
                { title: 'Username', property: 'username' },
                { title: 'Date', property: 'date' },
              ]}
            />
          )}
        </CrispoCard>
      </Sleeve>
    </AppShell>
  );
}

function Heading(props: { text: string }) {
  return <h2 className='mb-1'>{props.text}</h2>;
}
