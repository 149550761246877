import { IGroupDetails } from '../dto/IGroups';
import api from './useAPI';

type UniqueMembersResponse = { users: { _id: string; username: string }[] };

export default {
  async getUniqueMembers(groups: string[]): Promise<UniqueMembersResponse | undefined> {
    const q = groups.join(',');
    const res = await api.get(`groups/unique-members?g=${q}`);
    if (res.ok) {
      const data = await res.json();
      console.log(data);
      return data;
    }
  },

  async list() {
    return api.get('/groups').then((res) => res.json());
  },

  async updateGroupName(id: string, newName: string) {
    const res = await api.put(`groups/${id}`, { name: newName });
    if (res.ok) {
      const data = await res.json();
      return data;
    }
  },

  async fetchGroup(id: string) {
    const res = await api.get(`groups/${id}`);
    if (res.ok) {
      const data = (await res.json()) as IGroupDetails;
      return data;
    }
  },

  async addUsersToGroup(uids: string[], groupId: string) {
    const res = await api.put(`/groups/${groupId}/users`, {
      users: uids,
      delete: false,
    });
    if (res.ok) {
      const data = await res.json();
      return data;
    }
  },

  async removeUsersFromGroup(uids: string[], groupId: string) {
    const res = await api.put(`/groups/${groupId}/users`, {
      users: uids,
      delete: true,
    });
    if (res.ok) {
      const data = await res.json();
      return data;
    }
  },

  async remove(id: string) {
    const res = await api.del(`groups/${id}`);
    if (res.ok) {
      const data = await res.json();
      return data;
    }
  },
  async create(name: string) {
    const res = await api.post('/groups', { name });
    if (res.ok) {
      const data = await res.json();
      return data;
    }
  },
};
