import { BACKEND_URL } from '../config';
import join from 'url-join';
import qs from 'query-string';
import { useCallback } from 'react';

export function useExports() {
  const t = localStorage.getItem('token');
  const USER_STORE_URL = join(BACKEND_URL, `/export/user-store?t=${t}`);
  const getScanURL = useCallback(
    (start: number, end: number, sort: string, direction: number, limit: number, skip: number) => {
      // limit automatically gets set to the number of results per page displayed on the screen, but ultimately exports all date filtered results without limiting
      // skip automatically gets set to the number of pages navigated past in the table on the screen, but ultimately exports all date filtered results without skipping
      const q = qs.stringify({ start, end, sort, direction, limit, skip });
      return join(BACKEND_URL, `/scans/export?t=${t}&${q}`);
    },
    [t],
  );
  return { USER_STORE_URL, getScanURL };
}
