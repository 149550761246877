import React from 'react';
import { MdSearch } from 'react-icons/md';
import { CrispoInput } from './CrispoInput';

export const CrispoSearch = (props: { onChange: (e: any) => any; className?: string; placeholder?: string }) => (
  <div className=''>
    <CrispoInput
      icon={<MdSearch className='p-1 my-auto text-crispo' size='32px' />}
      onChange={props.onChange}
      placeholder={props.placeholder}
      type='search'
    />
  </div>
);
