import React from 'react';
import { CrispoInput } from '../../lib/CrispoInput';
import CrispoLabel from '../../lib/CrispoLabel';

interface Props {
  onSubjectChange: (subject: string) => any;
  onContentChange: (content: string) => any;
  onSendToChange: (sendTo: string) => any;
  onFilesChange: (file: any) => any;
}
export function EmailForm(props: Props) {
  const { onContentChange, onSubjectChange, onSendToChange, onFilesChange: onFileChange } = props;

  return (
    <div>
      <div className='mb-3'>
        <CrispoInput placeholder='Subject' label='Subject' onChange={(e) => onSubjectChange(e.target.value)} />
      </div>
      <div className='mb-3'>
        <CrispoLabel>Send To</CrispoLabel>
        <select
          className='block w-full p-2 bg-white border rounded outline-none focus:border-crispo-light'
          placeholder='Enter subject here...'
          onChange={(e) => onSendToChange(e.target.value)}
        >
          <option className='bg-white border'>---</option>
          <option className='bg-white border'>Selected Groups</option>
          <option className='bg-white border'>Everyone Except Selected Groups</option>
        </select>
      </div>
      <div className='mb-3'>
        <CrispoLabel>Content</CrispoLabel>

        <textarea
          className='block w-full p-2 border rounded outline-none focus:outline-none focus:border-crispo-light h-96 sm:text-sm sm:leading-5'
          placeholder='Enter email content here...'
          onChange={(e) => onContentChange(e.target.value)}
        />
      </div>
      <div>
        <CrispoInput
          type='file'
          label='Attachments'
          name='attachments'
          onChange={(ev) => {
            onFileChange(ev.currentTarget.files);
          }}
          multiple={true}
        />
        <p className='py-2 text-xs text-gray-700'>** Please note maximum combined size of attachments is 9Mb</p>
      </div>
    </div>
  );
}
