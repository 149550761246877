import React, { useState, useEffect } from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import { AppShell } from '../../lib/AppShell';
import { UserDetailCard } from './UserDetailCard';
import { IUserDetails } from '../../../dto/IUserDetails';
import { Sleeve } from '../../lib/Sleeve';
import UsersService from '../Users/UsersService';

interface Props extends RouteComponentProps {}
export function UserDetailScreen(props: Props) {
  const params = useParams();
  const [details, setDetails] = useState(null as IUserDetails | null);

  useEffect(() => {
    UsersService.getDetails(params.id).then(setDetails);
  }, [params.id]);

  function handleResetPassword(password: string) {
    UsersService.updatePassword(params.id, password).then((ok) => {
      if (ok) alert('Password updated successfully');
      else alert('Error while updating password');
    });
  }

  function handleAdminRoleChange(admin: boolean) {
    UsersService.setAdminRole(params.id, admin);
  }

  function handleUpdateBonus(points: number, tickets: number) {
    UsersService.setBonus(params.id, points, tickets)
      .then((ok) => {
        if (ok) alert('Successfully added bonus');
        else alert('Unable to add bonus');
      })
      .then(() => UsersService.getDetails(params.id).then(setDetails));
  }

  return (
    <AppShell>
      <Sleeve back title='User'>
        {details && (
          <UserDetailCard
            onUpdateBonus={handleUpdateBonus}
            onResetPassword={handleResetPassword}
            details={details}
            onAdminRoleChange={handleAdminRoleChange}
          />
        )}
      </Sleeve>
    </AppShell>
  );
}
