export interface DateObj {
  day: number;
  year: number;
  month: number;
}
export function daysFromNow(days: number) {
  const now = Date.now();
  const day = 1000 * 60 * 60 * 24;
  const arr = [];
  for (let i = 0; i <= days; i++) {
    arr.push({
      x: new Date(now - day * (days - i)),
      y: 0,
    });
  }
  return arr;
}

export function dateToObject(date: Date): DateObj {
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
  };
}

export function dateObjectsAreEqual(a: DateObj, b: DateObj) {
  return a.day === b.day && a.month === b.month && a.year === b.year;
}
