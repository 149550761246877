import React, { useState } from 'react';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoDatePicker from '../../lib/CrispoDatePicker';

export interface DrawFormProps {
  onSubmit(from: Date, to: Date): void;
  isLoading?: boolean;
}

export default function DrawForm(props: DrawFormProps) {
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit(from, to);
      }}
    >
      <div className='flex items-end'>
        <CrispoDatePicker
          label='From'
          selected={new Date(from)}
          onChange={(date: Date) => {
            setFrom(date);
          }}
        />
        <div className='mx-3'></div>
        <CrispoDatePicker
          label='To'
          selected={new Date(to)}
          onChange={(date: Date) => {
            setTo(date);
          }}
        />
        <div className='mx-3'></div>
        <CrispoButton isLoading={props.isLoading} className='h-12' title='Draw' secondary />
      </div>
    </form>
  );
}
