import { useLocation, useNavigate } from '@reach/router';
import React, { ReactNode } from 'react';
import { MdArrowBack } from 'react-icons/md';

interface Props {
  title: string;
  action?: ReactNode;
  back?: boolean;
  children?: any;
}

export function Sleeve(props: Props) {
  const nav = useNavigate();
  const location = useLocation();

  function back() {
    if (location.pathname === '/qr/print-preview') nav('/qr')
    else window.history.back()
  }

  return (
    <div className='container flex flex-col p-3 mx-auto'>
      <div className='flex items-center py-3'>
        {props.back && (
          <MdArrowBack
            className='no-print p-1 mr-3 text-3xl transition-colors duration-200 bg-gray-200 rounded-full cursor-pointer text-crispo text-dark hover:bg-gray-300'
            onClick={back}
          />
        )}
        <h1 className='no-print text-cool-gray-500'>{props.title}</h1>
        {props.action}
      </div>
      <div className='flex flex-col'>{props.children}</div>
    </div>
  );
}
