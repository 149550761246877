import api from '../../../hooks/useAPI';
import { RewardRawDto } from './RewardDto';

const RewardService = {
  state: {
    list: [],
  },
  async list() {
    return api
      .get('/rewards')
      .then((res) => res.json())
      .then((rewards) => {
        this.state.list = rewards;
        return rewards;
      });
  },
  async listTypes() {
    return api.get(`/rewards/types`).then((res) => res.json());
  },
  async findByType(type: string) {
    return api.get(`/rewards?type=${type}`).then((res) => res.json());
  },
  async find(id: string) {
    return api.get(`/rewards/${id}`).then((res) => res.json());
  },
  async create(reward: RewardRawDto) {
    return api.post(`/rewards`, reward).then((res) => res.json());
  },
  async delete(id: string) {
    return api.del(`/rewards/${id}`).then((res) => res.ok);
  },
  async update(id: string, reward: RewardRawDto) {
    return api.put(`/rewards/${id}`, reward).then((res) => res.json());
  },
};

export default RewardService;
