import React from 'react';
import { Redirect, Router } from '@reach/router';
import { AuthContextProvider } from './context/AuthContextProvider';
import { ChartContextProvider } from './context/ChartContextProvider';
import { ProtectedRoute } from './components/hoc/ProtectedRoute';
import { LoginScreen } from './components/screens/Login/LoginScreen';
import { OverviewScreen } from './components/screens/Overview/OverviewScreen';
import { UsersScreen } from './components/screens/Users/UsersScreen';
import { UserDetailScreen } from './components/screens/UserDetail/UserDetailScreen';
import { RewardsScreen } from './components/screens/Rewards/RewardsScreen';
import { ReedeemedScreen } from './components/screens/Reedeemed/ReedeemedScreen';
import { EmailScreen } from './components/screens/Email/EmailScreen';
import { ScansScreen } from './components/screens/Scans/ScansScreen';
import { GroupScreen } from './components/screens/Groups/GroupScreen';
import './styles/app.css';
import { GroupDetail } from './components/screens/GroupDetail/GroupDetail';
import { QrView } from './components/screens/QrView/QrView';
import ProductScreen from './components/screens/Product/ProductScreen';
import ProductEditScreen from './components/screens/Product/ProductEditScreen';
import ProductNewScreen from './components/screens/Product/ProductNewScreen';
import ProductGroupScreen from './components/screens/ProductGroup/ProductGroupScreen';
import ProductGroupEditScreen from './components/screens/ProductGroup/ProductGroupEditScreen';
import ProductGroupNewScreen from './components/screens/ProductGroup/ProductGroupNewScren';
import CrispointDaysScreen from './components/screens/CrispointDays/CrispointDaysScreen';
import RewardEditScreen from './components/screens/Rewards/RewardEditScreen';
import StatusScreen from './components/screens/Status/StatusScreen';
import StatusEditScreen from './components/screens/Status/StatusEditScreen';
import SettingsScreen from './components/screens/Settings/SettingsScreen';
import ContestScreen from './components/screens/Contest/ContestScreen';
import ContestDetailScreen from './components/screens/Contest/ContestDetailScreen';
import RedeemedDetailsScreen from './components/screens/Reedeemed/RedeemedDetailsScreen';
import { QrPrintPreview } from './components/screens/QrView/QrPrintPreview';

function App() {
  return (
    <AuthContextProvider>
      <ChartContextProvider>
        <Router>
          <LoginScreen default />
          <LoginScreen path='/login' />
          <Redirect path='/' from='/' to='/overview' />
          <ProtectedRoute path='/overview' component={OverviewScreen} />
          <ProtectedRoute path='/contests' component={ContestScreen} />
          <ProtectedRoute path='/contests/:id' component={ContestDetailScreen} />
          <ProtectedRoute path='/crispoint-days' component={CrispointDaysScreen} />
          <ProtectedRoute path='/email' component={EmailScreen} />
          <ProtectedRoute path='/groups' component={GroupScreen} />
          <ProtectedRoute path='/groups/:id' component={GroupDetail} />
          <ProtectedRoute path='/product-groups' component={ProductGroupScreen} />
          <ProtectedRoute path='/product-groups/:id/edit' component={ProductGroupEditScreen} />
          <ProtectedRoute path='/product-groups/new' component={ProductGroupNewScreen} />
          <ProtectedRoute path='/products' component={ProductScreen} />
          <ProtectedRoute path='/products/:id/edit' component={ProductEditScreen} />
          <ProtectedRoute path='/products/new' component={ProductNewScreen} />
          <ProtectedRoute path='/purchases' component={ReedeemedScreen} />
          <ProtectedRoute path='/purchases/:id' component={RedeemedDetailsScreen} />
          <ProtectedRoute path='/qr' component={QrView} />
          <ProtectedRoute path='/qr/print-preview' component={QrPrintPreview} />
          <ProtectedRoute path='/rewards' component={RewardsScreen} />
          <ProtectedRoute path='/rewards/:id' component={RewardEditScreen} />
          <ProtectedRoute path='/settings' component={SettingsScreen} />
          <ProtectedRoute path='/scans' component={ScansScreen} />
          <ProtectedRoute path='/status' component={StatusScreen} />
          <ProtectedRoute path='/status/:id' component={StatusEditScreen} />
          <ProtectedRoute path='/users' component={UsersScreen} />
          <ProtectedRoute path='/users/:id' component={UserDetailScreen} />
        </Router>
      </ChartContextProvider>
    </AuthContextProvider>
  );
}

export default App;
