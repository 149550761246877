import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ChartCard } from '../../lib/ChartCard';
import OverviewService from './OverviewService';
import { toChartData } from '../../../util/chart-util';

interface Props {}
export const ScansChart = React.memo((props: Props) => {
  const [scans, setScans] = useState<any[]>([]);
  const [scanDays, setScanDays] = useState(30);

  useEffect(() => {
    OverviewService.scanChart(scanDays)
      .then((scans) => toChartData(scans, scanDays))
      .then(setScans);
  }, [scanDays]);

  if (scans.length === 0) return <div></div>;

  return (
    <ChartCard
      title='Scans'
      onDateChange={setScanDays}
      activeDate={scanDays.toString()}
      number={scans.reduce((pr, curr) => {
        return pr + curr.y;
      }, 0)}
    >
      <ResponsiveLine
        data={[{ id: 'scans', data: scans }]}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false,
        }}
        curve='monotoneX'
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Scans',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        enableGridY={true}
        colors={{ scheme: 'accent' }}
        pointSize={10}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel='users'
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate={false}
        motionStiffness={95}
        motionDamping={0}
      />
    </ChartCard>
  );
});
