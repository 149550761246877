import { api } from '../services/api';

let _authenticated = false;

const AuthService = {
  isAuthenticated(): boolean {
    return _authenticated;
  },
  async login(username: string, password: string) {
    const res = await api.post('auth/login', {
      username,
      password,
    });
    if (res.status === 201 || res.status == 200) {
      const data: { token: string } = res.data;
      localStorage.setItem('token', data.token);
      _authenticated = true;
    } else {
      const err = res.data;
      _authenticated = false;
      throw err;
    }
  },
  async verify() {
    const res = await api.get('auth/verify');
    if (res.status === 200) {
      if (res.data.ok) {
        _authenticated = true;
      }
      return _authenticated;
    } else {
      _authenticated = false;
      return false;
    }
  },
  async register(username: string, password: string) {
    const res = await api.post('auth/register', {
      username,
      password,
    });
    if (res.status !== 200 && res.status !== 201) {
      const err = res.data;
      throw err;
    }
  },

  logout() {
    localStorage.removeItem('token');
    _authenticated = false;
  },
};

export default AuthService;
