import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useExports } from '../../../hooks/useExports';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { SortTable, ColumnData, Direction } from '../../lib/SortTable';
import { useNavigate } from '@reach/router';
import { CrispoSearch } from '../../lib/CrispoSearch';
import { CrispoButton } from '../../lib/CrispoButton';
import { CrispoPaginator, PageResults } from '../../lib/CrispoPaginator';
import UsersService from './UsersService';
import { IUser } from '../../../dto/IUser';

interface Props extends RouteComponentProps {}
export function UsersScreen(props: Props) {
  const navigate = useNavigate();
  const { USER_STORE_URL } = useExports();
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState('Sign Up Date');
  const [sort, setSort] = useState('signUpDate');
  const [direction, setDirection] = useState<Direction>(-1);
  const [totalUsers, setTotalUsers] = useState(UsersService.state.query.total);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const isMounted = useIsMounted();
  const [users, setUsers] = useState<IUser[]>(UsersService.state.query.users || []);
  const totalPages = useMemo(() => Math.ceil(totalUsers / limit), [totalUsers, limit]);

  useEffect(() => {
    UsersService.query(search, sort, direction, page, limit).then((res) => {
      setUsers(res.users);
      setTotalUsers(res.total);
    });
  }, [search, sort, direction, totalPages, page, limit, isMounted]);

  useEffect(() => {
    setPage(1);
  }, [search, limit, sort, direction]);

  const tableData = useMemo(
    () =>
      users.map((user: any) => {
        user.id = user._id;
        user.dateOfBirth = user.dateOfBirth ? new Date(user.dateOfBirth).toDateString() : '';
        user.signUpDate = new Date(user.signUpDate).toDateString();
        return user;
      }),
    [users],
  );

  function searchHandler(text: string) {
    setSearch(text);
  }

  function headerClickHandler(col: ColumnData, nextDirection: Direction) {
    setDirection(nextDirection);
    setSortColumn(col.title);
    setSort(col.property || '');
  }

  return (
    <AppShell>
      <Sleeve title='Users'>
        <div className='flex justify-between mb-3'>
          <CrispoSearch
            placeholder='Search for users...'
            onChange={(e) => {
              searchHandler(e.target.value);
            }}
          />
          <a href={USER_STORE_URL}>
            <CrispoButton secondary title='Download' />
          </a>
        </div>
        <div className='flex items-baseline justify-between mb-3'>
          {/* Header */}
          <PageResults
            className='flex items-center justify-end flex-1 text-sm'
            options={[10, 25, 50, 100]}
            activeOption={limit || 10}
            onOptionClick={setLimit}
          />
        </div>
        {/* Table */}
        <div className='mb-10'>
          <SortTable
            sortColumn={sortColumn}
            direction={direction}
            onHeaderClick={headerClickHandler}
            forceDirection
            onItemClick={(user) => navigate(`users/${user._id}`)}
            data={tableData}
            columns={[
              {
                title: 'Username',
                property: 'username',
              },
              {
                title: 'Store',
                property: 'store',
              },
              {
                title: 'First Name',
                property: 'firstName',
              },
              {
                title: 'Last Name',
                property: 'lastName',
              },
              {
                title: 'Date of Birth',
                property: 'dateOfBirth',
              },
              {
                title: 'Sign Up Date',
                property: 'signUpDate',
              },
            ]}
          />
          <div className='items-center justify-center mt-5 mb-5 xl:flex'>
            <span className='flex-1 text-xs text-left'>
              Showing {limit * page - limit + 1}-{Math.min(limit * page, totalUsers)} out of {totalUsers} results
            </span>
            {totalPages > 1 && <CrispoPaginator pageCount={totalPages} currentPage={page} onPageChange={setPage} />}
            <span className='flex-1' />
          </div>
        </div>
      </Sleeve>
    </AppShell>
  );
}
