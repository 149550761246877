import React from 'react';
import * as Md from 'react-icons/md';
import AuthService from '../../hooks/AuthService';
import { SideNavLink } from './SideNavLink';

export const AppShell: React.FC = (props) => {
  return (
    <div className='relative flex h-full min-h-screen overflow-hidden'>
      <div className='sticky top-0 h-full min-h-screen overflow-auto bg-white shadow-lg w-72'>
        <nav className='mx-3'>
          <SideNavLink to='/overview' text='Overview' icon={Md.MdAssessment} />
          <SideNavLink to='/users' text='Users' icon={Md.MdSupervisorAccount} />
          <SideNavLink to='/scans' text='Scans' icon={Md.MdGraphicEq} />
          <SideNavLink to='/rewards' text='Rewards' icon={Md.MdStar} />
          <SideNavLink to='/purchases' text='Redeemed' icon={Md.MdCardGiftcard} />
          <SideNavLink to='/crispoint-days' text='Crispoint Days' icon={Md.MdDateRange} />
          <SideNavLink to='/status' text='Statuses' icon={Md.MdVerifiedUser} />
          <SideNavLink to='/qr' text='Qr Codes' icon={Md.MdScanner} />
          <SideNavLink to='/products' text='Products' icon={Md.MdShoppingBasket} />
          <SideNavLink to='/product-groups' text='Product Groups' icon={Md.MdGroupWork} />
          <SideNavLink to='/contests' text='Contests' icon={Md.MdContacts} />
          <SideNavLink to='/email' text='Email' icon={Md.MdEmail} />
          <SideNavLink to='/groups' text='Groups' icon={Md.MdPersonAdd} />
          <SideNavLink to='/settings' text='Settings' icon={Md.MdSettings} />
          <SideNavLink onClick={AuthService.logout} to='/login' text='Logout' icon={Md.MdExitToApp} />
          <div className='mb-6'></div>
        </nav>
      </div>
      <div className='flex flex-col flex-1 flex-grow overflow-y-auto bg-cool-gray-50' id='main-window'>
        {props.children}
      </div>
    </div>
  );
};
