import React from 'react';

interface Props {
  data: string[];
  active?: string;
  onClick?: (name: string) => any;
}
export function DateRangeSelector(props: Props) {
  const { data, onClick = () => {}, active = data[0] } = props;

  return (
    <span className='relative z-0 inline-flex bg-white border rounded-md shadow-md '>
      {data.map((el) => {
        const activeClass = el === active ? ' text-crispo' : ' text-gray-700';
        return (
          <button
            key={el}
            type='button'
            onClick={() => onClick(el)}
            className={
              'relative inline-flex items-center px-4 py-2 text-sm leading-5 font-medium hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150' +
              activeClass
            }
          >
            {el}d
          </button>
        );
      })}
    </span>
  );
}
