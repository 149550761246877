import React, { useEffect, useState } from 'react';
import ProductService from '../../../hooks/ProductService';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput } from '../../lib/CrispoInput';
import CrispoLabel from '../../lib/CrispoLabel';
import ItemSelection from '../../lib/ItemSelection';
import { SelectionItem } from '../../lib/ItemSelectionList';
import ProductGroupService, { IProductGroup } from '../ProductGroup/ProductGroupService';
import { IProduct } from '../Reedeemed/RedeemedService';
import { RewardDto, RewardRawDto } from './RewardDto';

export interface RewardFormProps {
  reward?: RewardDto;
  onSubmit(reward: RewardRawDto): void;
}
export default function RewardForm(props: RewardFormProps) {
  const [title, setTitle] = useState(props.reward?.title || 'Untitled Reward');
  const [points, setPoints] = useState(props.reward?.points || 0);
  const [tickets, setTickets] = useState(props.reward?.tickets || 0);
  const [products, setProducts] = useState(props.reward?.products || []);
  const [productSelections, setProductSelections] = useState(props.reward?.productSelections || []);

  const [availableProducts, setAvailableProducts] = useState<IProduct[]>([]);
  const [availableProductGroups, setAvailableProductGroups] = useState<IProductGroup[]>([]);

  useEffect(() => {
    ProductService.list().then(setAvailableProducts);
    ProductGroupService.list().then(setAvailableProductGroups);
  }, []);

  function onAddProducts(items: SelectionItem[]) {
    setProducts(products.concat(items.map((i) => i.item)));
  }

  function onRemoveProducts(items: SelectionItem[]) {
    setProducts(products.filter((p) => !items.find((i) => i._id === p._id)));
  }

  function onAddProductSelections(items: SelectionItem[]) {
    setProductSelections(productSelections.concat(items.map((i) => i.item)));
  }

  function onRemoveProductSelections(items: SelectionItem[]) {
    setProductSelections(productSelections.filter((p) => !items.find((i) => i._id === p._id)));
  }

  function onSubmit() {
    props.onSubmit({
      title: title,
      points: points,
      tickets: tickets,
      products: products.map((p) => p._id!),
      productSelections: productSelections.map((ps) => ps._id),
    });
  }

  return (
    <CrispoCard>
      <form onSubmit={(e) => e.preventDefault()}>
        <CrispoInput label='Title' name='title' onChange={(e) => setTitle(e.target.value)} value={title} />
        <div className='flex'>
          <CrispoInput
            label='Points'
            name='points'
            onChange={(e) => setPoints(parseInt(e.target.value))}
            type='number'
            value={points}
          />
          <div className='mx-3'></div>
          <CrispoInput
            label='Tickets'
            name='tickets'
            onChange={(e) => setTickets(parseInt(e.target.value))}
            type='number'
            value={tickets}
          />
        </div>
        <div className='my-3'></div>

        <CrispoLabel>Products</CrispoLabel>
        {availableProducts && products && (
          <ItemSelection
            leftTitle='Available Products'
            leftItems={availableProducts
              .filter((ap) => !products.find((p) => p._id === ap._id))
              .map((ap) => ({ _id: ap._id!, item: ap, text: ap.title }))}
            onAddItems={onAddProducts}
            onRemoveItems={onRemoveProducts}
            rightTitle='Products'
            rightItems={products.map((p) => ({ _id: p._id!, item: p, text: p.title }))}
          />
        )}

        <div className='my-3'></div>

        <CrispoLabel>Product Selections</CrispoLabel>
        <ItemSelection
          leftTitle='Available Product Groups'
          leftItems={availableProductGroups
            .filter((ap) => !productSelections.find((p) => p._id === ap._id))
            .map((ap) => ({ _id: ap._id!, item: ap, text: ap.title }))}
          onAddItems={onAddProductSelections}
          onRemoveItems={onRemoveProductSelections}
          rightTitle='Product Selections'
          rightItems={productSelections.map((p) => ({ _id: p._id!, item: p, text: p.title }))}
        />
        <div className='my-3'></div>
        <CrispoButton title='Submit' onClick={onSubmit} />
      </form>
    </CrispoCard>
  );
}
