import React, { useEffect } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CrispoLabel from './CrispoLabel';

export interface CrispoDatePickerProps extends ReactDatePickerProps {
  label?: string;
}

export default function CrispoDatePicker({ label, ...props }: CrispoDatePickerProps) {
  useEffect(() => {}, []);

  return (
    <div>
      {label && (
        <>
          <CrispoLabel>{label}</CrispoLabel>
          <br></br>
        </>
      )}
      <ReactDatePicker
        onCalendarOpen={() => {
          const datePicker = document.querySelector('.react-datepicker');
          datePicker?.classList.add('font-sans', 'shadow-lg', 'border-0');

          const header = document.querySelector('.react-datepicker__header');
          header?.classList.add('bg-crispo', 'text-white');

          const month = document.querySelector('.react-datepicker__current-month');
          month?.classList.add('text-white', 'font-normal');

          const triangle = document.querySelector('.react-datepicker__triangle');
          triangle?.classList.add('border-crispo');
        }}
        monthClassName={() => 'text-white'}
        weekDayClassName={() => 'text-white'}
        className='w-full h-12 p-2 m-0 bg-white border rounded outline-none focus:outline-none focus:border-crispo-light'
        calendarClassName='bg-white text-crispo'
        {...props}
      />
    </div>
  );
}
