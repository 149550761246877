import React, { useState } from 'react';
import { LoginForm } from './LoginForm';
import { useNavigate } from '@reach/router';
import AuthService from '../../../hooks/AuthService';

export function LoginScreen(props: any) {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  async function handleLogin(username: string, password: string) {
    try {
      await AuthService.login(username, password);
      navigate('/');
    } catch (err) {
      console.error(err);
      setError('Login failed');
    }
  }

  return (
    <div className='h-full bg-gray-900'>
      <div className='flex flex-col justify-center w-full h-full max-w-lg mx-auto'>
        <LoginForm onLogin={handleLogin} error={error} />
      </div>
    </div>
  );
}
