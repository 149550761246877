import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { SortTable } from '../../lib/SortTable';
import { useNavigate } from '@reach/router';
import RedeemedService, { IRedemption } from './RedeemedService';

export const ReedeemedScreen = () => {
  const navigate = useNavigate();
  const [redemptions, setRedemptions] = useState<IRedemption[]>([]);

  useEffect(() => {
    RedeemedService.list().then(setRedemptions);
  }, []);

  const RewardSentCheckBox = (props: { item: any }) => (
    <input
      className=''
      type='checkbox'
      defaultChecked={props.item.sent}
      onClick={(e) => e.stopPropagation()}
      onChange={async (change) => {
        RedeemedService.updateSent(props.item.id!, change.currentTarget.checked);
      }}
    />
  );

  const tableData = redemptions.map((r) => {
    return {
      id: r._id!,
      username: r.user.username,
      reward: r.reward,
      type: r.type,
      sent: r.sent,
      date: new Date(r.date).toLocaleDateString(),
    };
  });

  return (
    <AppShell>
      <Sleeve title='Redeemed'>
        <div className='mb-10'>
          <SortTable
            sortColumn='Username'
            direction={0}
            onItemClick={(obj) => {
              navigate(`/purchases/${obj.id}`);
            }}
            forceDirection
            columns={[
              {
                title: 'Username',
                property: 'username',
              },
              {
                title: 'Reward',
                property: (i) => i.reward.title,
              },
              { title: 'Date', property: 'date' },
              {
                title: 'Reward Sent',
                property: 'sent',
                component: RewardSentCheckBox,
              },
            ]}
            data={tableData}
          />
        </div>
      </Sleeve>
    </AppShell>
  );
};
