import React from 'react';
import { DirectionIcon } from './DirectionIcon';

interface SortTableColumnHeaderProps {
  title: string;
  hideTitle?: boolean;
  direction?: number;
  className?: string;
  onClick?: (name: string) => any;
}
export function SortTableColumnHeader(props: SortTableColumnHeaderProps) {
  return (
    <th
      onClick={() => {
        if (props.onClick) props.onClick(props.title);
      }}
      className={[
        'px-6 py-4 text-xs font-medium tracking-wider text-white leading-4',
        'overflow-ellipsis',
        `uppercase bg-crispo`,
        props.onClick && 'hover:bg-crispo-dark cursor-pointer transition-colors duration-200',
      ].join(' ')}
    >
      <span className='flex select-none overflow-ellipsis'>
        <span className='overflow-ellipsis'>{props.title}</span>
        <DirectionIcon direction={props.direction} size='16px' />
      </span>
    </th>
  );
}
