import { useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { MdArrowBack, MdArrowForward, MdEdit } from 'react-icons/md';
import { IGroupDetails } from '../../../dto/IGroups';
import GroupService from '../../../hooks/GroupService';
import { AppShell } from '../../lib/AppShell';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput } from '../../lib/CrispoInput';
import { SelectionItem, ItemSelectionList } from '../../lib/ItemSelectionList';
import { Sleeve } from '../../lib/Sleeve';

function UpdateName(props: { name: string; onSubmit: (newName: string) => any }) {
  const [newName, setNewName] = useState(props.name);
  return (
    <div className='flex mb-6 align-middle rounded-lg'>
      <CrispoInput
        label='Group Name'
        placeholder='Change group name...'
        icon={
          <MdEdit
            title='Submit'
            className='p-2 my-auto cursor-pointer text-crispo'
            size={32}
            onClick={(e) => {
              if (newName === '') {
                return alert('Please enter a valid name');
              }
              props.onSubmit(newName);
              setNewName('');
            }}
          />
        }
        onChange={(e) => {
          setNewName(e.target.value);
        }}
        value={newName}
      />
    </div>
  );
}

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
interface ItemButtonProps extends ButtonProps {
  title: any;
}
function AddItemButton(props: ItemButtonProps) {
  return (
    <button
      {...props}
      className='px-3 py-2 text-gray-800 transition-colors duration-150 bg-green-200 rounded shadow hover:bg-green-300 hover:text-gray-900'
    >
      {props.title}
    </button>
  );
}

function RemoveItemButton(props: ItemButtonProps) {
  return (
    <button
      {...props}
      className='px-3 py-2 text-gray-800 transition-colors duration-150 bg-red-200 rounded shadow hover:bg-red-300 hover:text-gray-900'
    >
      {props.title}
    </button>
  );
}

export function GroupDetail() {
  const [group, setGroup] = useState<IGroupDetails>();
  const [allGroup, setAllGroup] = useState<IGroupDetails>();
  const [removeList, setRemoveList] = useState<string[]>([]);
  const [addList, setAddList] = useState<string[]>([]);
  const params = useParams();

  useEffect(() => {
    GroupService.fetchGroup(params.id).then(setGroup);
    GroupService.fetchGroup('all').then(setAllGroup);
  }, [params.id]);

  function addToAddList(id: string) {
    if (!addList.includes(id)) {
      setAddList([...addList, id]);
    }
  }

  function addToRemoveList(id: string) {
    if (!removeList.includes(id)) {
      setRemoveList([...removeList, id]);
    }
  }

  function handleRemoveMembers(checked: boolean, user: SelectionItem) {
    if (checked) {
      addToRemoveList(user._id);
    } else {
      const list = removeList.filter((x) => x !== user._id);
      setRemoveList(list);
    }
  }

  function handleAddMembers(checked: boolean, user: SelectionItem) {
    if (checked) {
      addToAddList(user._id);
    } else {
      const list = addList.filter((x) => x !== user._id);
      setAddList(list);
    }
  }

  async function removeMembersReq() {
    if (removeList.length === 0) return;
    await GroupService.removeUsersFromGroup(removeList, params.id);
    const group = await GroupService.fetchGroup(params.id);
    setRemoveList([]);
    setGroup(group);
  }

  async function addMembersReq() {
    if (addList.length === 0) return;
    await GroupService.addUsersToGroup(addList, params.id);
    const group = await GroupService.fetchGroup(params.id);
    setAddList([]);
    setGroup(group);
  }

  return (
    <AppShell>
      {group && allGroup ? (
        <Sleeve back title='Group Edit'>
          <CrispoCard>
            <UpdateName
              name={group.name}
              onSubmit={async (newName) => {
                await GroupService.updateGroupName(params.id, newName);
                GroupService.fetchGroup(params.id).then(setGroup);
              }}
            />
            <div className='flex justify-around'>
              <div className='flex flex-col w-full'>
                <ItemSelectionList
                  onSelectAll={() => setAddList(allGroup.users.map((u) => u._id))}
                  onSelectNone={() => setAddList([])}
                  onItemSelect={handleAddMembers}
                  selectedIds={addList}
                  title='Available Members'
                  items={
                    group.users.length
                      ? allGroup.users
                          .filter((x: any) => {
                            const user = group.users.find((u: any) => u._id === x._id);
                            return !user;
                          })
                          .map((u) => ({ _id: u._id, text: u.username }))
                      : allGroup.users.map((u) => ({ _id: u._id, text: u.username }))
                  }
                />
              </div>
              <div className='flex flex-col justify-center w-1/6 mx-6 h-100'>
                <AddItemButton title={<MdArrowForward size={24} className='mx-auto' />} onClick={addMembersReq} />
                <div className='h-1/12'></div>
                <RemoveItemButton title={<MdArrowBack size={24} className='mx-auto' />} onClick={removeMembersReq} />
              </div>
              <div className='flex flex-col w-full'>
                <ItemSelectionList
                  title='Current Group Members'
                  selectedIds={removeList}
                  onSelectAll={() => setRemoveList(group.users.map((u) => u._id))}
                  onSelectNone={() => setRemoveList([])}
                  items={group.users.map((u) => ({ _id: u._id, text: u.username }))}
                  onItemSelect={handleRemoveMembers}
                />
              </div>
            </div>
          </CrispoCard>
        </Sleeve>
      ) : (
        <Sleeve title='Loading...' />
      )}
    </AppShell>
  );
}
