import React from 'react';

type Label = React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
export default function CrispoLabel({ children, className, ...props }: Label) {
  return (
    <label {...props} className='mb-1 text-xs font-semibold text-cool-gray-600'>
      {children}
    </label>
  );
}
