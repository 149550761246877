import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { SortTable } from '../../lib/SortTable';
import { StatusDto } from './StatusDto';
import StatusService from './StatusService';

export default function StatusScreen() {
  const [statuses, setStatuses] = useState<StatusDto[]>(StatusService.cache.list);
  const nav = useNavigate();

  useEffect(() => {
    StatusService.list().then(setStatuses);
  }, []);

  return (
    <AppShell>
      <Sleeve title='Statuses'>
        <SortTable
          onItemClick={(i) => nav(`/status/${i._id}`)}
          direction={0}
          sortColumn='Title'
          data={statuses.map((s) => ({ id: s._id!, ...s }))}
          columns={[
            { title: 'Title', property: 'title' },
            { title: 'Scans Required', property: 'scansRequired' },
            { title: 'Scan Modifier', property: 'scanModifier' },
            { title: 'Ticket Rate', property: 'ticketRate' },
          ]}
        />
      </Sleeve>
    </AppShell>
  );
}
