import api from '../../../hooks/useAPI';
import qs from 'query-string';

const ScanService = {
  state: { query: { scans: [], total: 0 } },
  async query(limit: number, skip: number, start: number, end: number, sort: string, direction: number) {
    const q = qs.stringify({ limit, skip, start, end, sort, direction });

    return api
      .get(`/scans?${q}`)
      .then((res) => res.json())
      .then((data) => {
        this.state.query = data;
        return data;
      });
  },
};

export default ScanService;
