import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import GroupService from '../../../hooks/GroupService';
import { AppShell } from '../../lib/AppShell';
import { CrispoInput } from '../../lib/CrispoInput';
import { Sleeve } from '../../lib/Sleeve';
import { SortTable } from '../../lib/SortTable';
import { IGroup } from '../../../dto/IGroups';

interface GroupScreenProps {}
export function GroupScreen(props: GroupScreenProps) {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [groupName, setGroupName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    GroupService.list().then(setGroups);
  }, []);

  async function create() {
    GroupService.create(groupName).then(() => GroupService.list().then(setGroups));
  }

  async function remove(id: string) {
    GroupService.remove(id).then(() => GroupService.list().then(setGroups));
  }

  return (
    <AppShell>
      <Sleeve title='Groups'>
        <div className='mb-10'>
          <div className='flex mb-3'>
            <CrispoInput
              placeholder='Enter group name...'
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
              type='text'
              className='mr-3'
            />
            <MdAdd
              //title="Add Group"
              className='p-2 text-white bg-green-500 rounded-full shadow cursor-pointer hover:bg-green-600'
              size={32}
              onClick={(_) => {
                create();
                setGroupName('');
              }}
            />
          </div>
          <SortTable
            data={groups
              .map((group) => {
                let out: any = group;
                out.count = group.users.length;
                out.id = group._id;
                return out;
              })
              .reverse()}
            sortColumn='name'
            direction={0}
            id='_id'
            forceDirection
            onItemClick={(i) => {
              navigate(`/groups/${i._id}`);
            }}
            columns={[
              {
                title: 'Name',
                property: 'name',
                component: (obj) => (
                  <div className='flex'>
                    {obj.item.name}
                    <MdRemove
                      onClick={(e) => {
                        e.stopPropagation();
                        const result = window.confirm(`Are you sure you want to delete "${obj.item.name}" group?`);
                        if (result) remove(obj.item._id);
                      }}
                      size={32}
                      className='p-2 ml-auto mr-3 text-white bg-red-500 rounded-full shadow hover:bg-red-600'
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </Sleeve>
    </AppShell>
  );
}
