import React, { useRef, useState } from 'react';
import { IUserDetails } from '../../../dto/IUserDetails';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput } from '../../lib/CrispoInput';

const FormEntry = (props: { label: string; value: any; hint?: string }) => {
  const { label, value, hint } = props;
  return (
    <div className='border-b sm:grid sm:grid-cols-3 sm:gap-4'>
      <dt className='p-2 text-sm font-medium leading-5 border-r'>
        {label}
        {hint && (
          <div className='mt-2'>
            <span className='text-xs italic font-normal text-gray-400'>{hint}</span>
          </div>
        )}
      </dt>
      <dd className='p-2 text-sm leading-5 sm:mt-0 sm:col-span-2'>{value}</dd>
    </div>
  );
};

interface Props {
  details: IUserDetails;
  onAdminRoleChange: (status: boolean) => any;
  onResetPassword(password: string): void;
  onUpdateBonus(points: number, tickets: number): void;
}
export function UserDetailCard(props: Props) {
  const { details, onAdminRoleChange: setAdminStatus } = props;
  const adminCheckbox = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState('');
  const [bonusPoints, setBonusPoints] = useState(props.details.bonus?.points || 0);
  const [bonusTickets, setBonusTickets] = useState(props.details.bonus?.tickets || 0);

  function adminCheckboxHandler() {
    if (adminCheckbox.current) {
      setAdminStatus(adminCheckbox.current.checked);
    }
  }

  return (
    <CrispoCard dense>
      <h3 className='p-2 text-lg font-medium leading-6 border-b text-crispo'>{details.user.username}</h3>
      <FormEntry label='Username' value={details.user.username} />
      <FormEntry label='First Name' value={details.user.firstName} />
      <FormEntry label='Last Name' value={details.user.lastName} />
      <FormEntry label='Date of Birth' value={new Date(details.user.dateOfBirth).toLocaleDateString()} />
      <FormEntry label='Sign Up Date' value={new Date(details.user.signUpDate).toDateString()} />
      <FormEntry label='Store' value={details.user.store} />
      <FormEntry label='Point Balance' value={details.balance} />
      <FormEntry label='Ticket Entries' value={details.ticketEntries} />
      <FormEntry label='Total Scans' value={details.totalScans} />
      <FormEntry label='Total Points Accumulated' value={details.totalPoints} />
      <FormEntry
        label='Admin Status'
        value={
          <input
            type='checkbox'
            onChange={adminCheckboxHandler}
            ref={adminCheckbox}
            defaultChecked={details.user.role === 'admin'}
          />
        }
        hint='Allows user to gain access to the dashboard'
      />
      <FormEntry
        label='Password Reset'
        value={
          <CrispoInput
            type='password'
            autoComplete='off'
            autoSave='off'
            placeholder='********'
            label='Password'
            onChange={(e) => setPassword(e.target.value)}
            icon={
              <CrispoButton
                secondary
                box
                className='rounded-r'
                title='Reset'
                onClick={() => props.onResetPassword(password)}
              />
            }
          />
        }
      />
      <FormEntry
        hint='Add or remove points/tickets to the user. To remove type in negative values'
        label='Bonus'
        value={
          <div className='flex items-end'>
            <CrispoInput
              label='Points'
              type='number'
              onChange={(e) => setBonusPoints(parseInt(e.target.value))}
              value={bonusPoints}
            />
            <div className='mx-3'></div>
            <CrispoInput
              label='Tickets'
              type='number'
              onChange={(e) => setBonusTickets(parseInt(e.target.value))}
              value={bonusTickets}
            />
            <div className='mx-3'></div>
            <CrispoButton title='Update' onClick={() => props.onUpdateBonus(bonusPoints, bonusTickets)} />
          </div>
        }
      />
      <FormEntry label='ID' value={details.user._id} />
    </CrispoCard>
  );
}
