import React from 'react';
import { DateRangeSelector } from '../screens/Overview/DateRangeSelector';
import CrispoCard from './CrispoCard';

export interface ChartCardProps {
  title: string;
  number: number;
  onDateChange?: (date: number) => any;
  children?: any;
  activeDate?: string;
}
export function ChartCard({
  title,
  children,
  onDateChange = (str: number) => {},
  activeDate = '7',
  number,
}: ChartCardProps) {
  return (
    <CrispoCard>
      <div className='flex items-baseline'>
        <div className='mt-3 ml-6 text-md'>
          <h2 className='tracking-wider uppercase text-crispo-blue'>{title}</h2>
          <h3 className='mt-1 text-sm text-gray-500'>
            <span className='text-black'>{number}</span> in the last <span className='text-black'>{activeDate}</span>{' '}
            days
          </h3>
        </div>
        <div className='ml-auto'>
          <DateRangeSelector
            data={['7', '14', '30', '60']}
            onClick={(el) => onDateChange(parseInt(el))}
            active={activeDate}
          />
        </div>
      </div>
      <div className='h-72'>{children}</div>
    </CrispoCard>
  );
}
