import React, { useEffect, useState } from 'react';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput } from '../../lib/CrispoInput';
import CrispoLabel from '../../lib/CrispoLabel';
import { IProduct } from '../Reedeemed/RedeemedService';

export interface ProductFormProps {
  product?: IProduct;
  isLoading?: boolean;
  onSubmit: (p: { title: string; options: string[]; image?: File }) => void;
}

export default function ProductForm({ product, onSubmit, isLoading }: ProductFormProps) {
  const [title, setTitle] = useState(product?.title ?? '');
  const [options, setOptions] = useState(product?.options ?? []); //
  const [option, setOption] = useState('');
  const [image, setImage] = useState<File>();
  const [fileUri, setFileUri] = useState<any>('');

  useEffect(() => setTitle(product?.title ?? ''), [product]);
  useEffect(() => setOptions(product?.options ?? []), [product]);

  function onFileChange(file?: File | null) {
    if (!file) return;
    setImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => setFileUri(e.target?.result);
  }

  function onAddOption() {
    if (!option) return;
    setOptions([...options, option]);
    setOption('');
  }

  function onOptionChange(opt: string, index: number) {
    options[index] = opt;
    setOptions([...options]);
  }

  function onOptionDelete(_: string, index: number) {
    options.splice(index, 1);
    setOptions([...options]);
  }

  return (
    <CrispoCard>
      <form
        className='flex flex-col'
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className='flex'>
          <div className='flex flex-col mr-6'>
            <div>
              <CrispoLabel htmlFor='image'>Image</CrispoLabel>
              <br />
              <div className='w-64 h-64 mb-3 bg-white border'>
                <img className='object-contain w-full h-full' src={fileUri || product?.imageUrl} alt='product_image' />
              </div>
              <CrispoInput
                style={{ textOverflow: 'ellipsis' }}
                className='w-64'
                onChange={(e) => onFileChange(e.target.files?.item(0))}
                type='file'
                name='image'
              />
            </div>
          </div>
          <div className='flex-col flex-1 left'>
            <CrispoInput
              label='Title'
              type='text'
              name='title'
              className='w-full'
              placeholder='Title'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />

            <div className='flex'>
              <div className='flex-1'>
                <CrispoInput
                  icon={<CrispoButton box className='rounded-r' title='+' onClick={onAddOption} />}
                  label='Options'
                  type='text'
                  className='flex-1'
                  name='option'
                  placeholder='Add Option'
                  value={option}
                  onChange={(e) => setOption(e.target.value)}
                />
              </div>
            </div>

            <ul className='flex flex-wrap my-3'>
              {options.map((opt, i) => (
                <div key={i} className='flex p-2 mb-1 mr-3 text-sm text-center bg-gray-200 rounded w-min'>
                  <input
                    className='text-center bg-gray-200'
                    flex-1key={i}
                    onChange={(e) => onOptionChange(e.target.value, i)}
                    size={opt.length === 0 ? 1 : opt.length}
                    width={opt.length + ''}
                    value={opt}
                  />
                  <button
                    onClick={(e) => onOptionDelete(opt, i)}
                    className='flex items-center justify-center w-6 h-6 p-1 m-auto text-gray-400 border border-gray-400 '
                  >
                    x
                  </button>
                </div>
              ))}
            </ul>
          </div>
        </div>
        <div className='flex mt-3'>
          <CrispoButton
            type='submit'
            title='Submit'
            isLoading={isLoading}
            onClick={(e) => onSubmit({ title, options, image })}
          />
        </div>
      </form>
    </CrispoCard>
  );
}
