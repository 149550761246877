import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput, CrispoInputClassName } from '../../lib/CrispoInput';
import CrispoLabel from '../../lib/CrispoLabel';
import { Sleeve } from '../../lib/Sleeve';
import { RewardDto } from '../Rewards/RewardDto';
import RewardService from '../Rewards/RewardService';
import SettingsService from './SettingsService';

export default function SettingsScreen() {
  const [rewards, setRewards] = useState<RewardDto[]>([]);
  const [settingsMap, setSettingsMap] = useState<Record<string, { value: any; type: string }>>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Promise.all([
      SettingsService.list().then((settings) => {
        settings.forEach((setting) => {
          Object.assign(settingsMap, { [setting.key]: { ...setting } });
          setSettingsMap({ ...settingsMap });
        });
      }),
      RewardService.list().then(setRewards),
    ]);
  }, []);

  function updateSettings(key: string, value: any, type: string) {
    Object.assign(settingsMap, { [key]: { value, type } });
    setSettingsMap({ ...settingsMap });
  }

  async function onSubmit() {
    setIsLoading(true);
    return Promise.all(
      Object.keys(settingsMap).map((key) => {
        const { value, type } = settingsMap[key];
        return SettingsService.update(key, value, type);
      }),
    )
      .then(() => alert('Settings updated successfully'))
      .finally(() => setIsLoading(false));
  }

  return (
    <AppShell>
      <Sleeve title='Settings'>
        <CrispoCard>
          <form onSubmit={(e) => e.preventDefault()}>
            {Object.keys(settingsMap)
              .sort()
              .map((key) => {
                switch (settingsMap[key].type) {
                  case 'text':
                    return (
                      <div className='mb-3' key={key}>
                        <CrispoLabel>{key}</CrispoLabel>
                        <br />
                        <select
                          className={CrispoInputClassName}
                          value={settingsMap[key].value}
                          onChange={(e) => updateSettings(key, e.currentTarget.value, settingsMap[key].type)}
                        >
                          {rewards.map((reward) => (
                            <option key={reward._id} value={reward._id}>
                              {reward.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  default:
                    return (
                      <CrispoInput
                        key={key}
                        onChange={(e) => updateSettings(key, e.currentTarget.value, settingsMap[key].type)}
                        label={key}
                        type={settingsMap[key].type}
                        readOnly
                        value={settingsMap[key].value}
                      ></CrispoInput>
                    );
                }
              })}

            <div className='mb-3'></div>
            <CrispoButton sm isLoading={isLoading} title='Save' onClick={onSubmit} />
          </form>
        </CrispoCard>
      </Sleeve>
    </AppShell>
  );
}
