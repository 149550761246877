import api from '../../../hooks/useAPI';
import { StatusDto } from './StatusDto';

const StatusService = {
  cache: { list: [] },
  async list() {
    return api
      .get('status')
      .then((res) => res.json())
      .then((data) => {
        this.cache.list = data;
        return data;
      });
  },

  async find(id: string) {
    return api.get(`status/${id}`).then((res) => res.json());
  },

  async update(id: string, status: StatusDto) {
    return api.put(`status/${id}`, status).then((res) => res.json());
  },
};

export default StatusService;
