import React from 'react';
import { MdGraphicEq, MdRedeem, MdPeopleOutline } from 'react-icons/md';
import CrispoCard from '../../lib/CrispoCard';

interface StatProps {
  users: number;
  scans: number;
  rewards: number;
}
export const TotalStats = (props: StatProps) => {
  return (
    <CrispoCard>
      <div className='flex flex-wrap justify-center w-full'>
        <StatItem
          title='Total Users'
          value={props.users}
          icon={<MdPeopleOutline className='text-green-500' size='40px' />}
        />
        <StatItem
          title='Total Scans'
          value={props.scans}
          icon={<MdGraphicEq className='text-purple-500' size='40px' />}
        />
        <StatItem
          title='Total Rewards Redeemed'
          value={props.rewards}
          icon={<MdRedeem className='text-crispo' size='40px' />}
        />
      </div>
    </CrispoCard>
  );
};

interface StatItemProps {
  icon: any;
  title: string;
  value: any;
}
const StatItem = (props: StatItemProps) => {
  const { icon, title, value } = props;
  return (
    <div className='flex-1'>
      <div className='px-4 py-5 sm:p-6'>
        <div className='flex items-center'>
          {icon}
          <div className='flex-1 w-0 ml-5'>
            <dl>
              <dt className='text-sm font-medium leading-5 text-gray-500 truncate'>{title}</dt>
              <dd className='flex items-baseline'>
                <div className='text-2xl font-semibold leading-8 text-gray-900'>{value}</div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
