import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { RewardDto } from './RewardDto';
import RewardService from './RewardService';
import { SortTable } from '../../lib/SortTable';

export function RewardsScreen(props: RouteComponentProps) {
  const [rewards, setRewards] = useState<RewardDto[]>(RewardService.state.list);
  const navigate = useNavigate();

  useEffect(() => {
    RewardService.list().then(setRewards);
  }, []);

  const tableData = rewards.map((reward) => ({
    ...reward,
    id: reward._id!,
    products: reward.products?.map((p) => p.title).join(', '),
    productSelections: reward.productSelections?.map((p) => p.title).join(', '),
  }));

  return (
    <AppShell>
      <Sleeve title='Rewards'>
        <SortTable
          onItemClick={(i) => navigate('/rewards/' + i._id)}
          columns={[
            { title: 'Title', property: 'title' },
            { title: 'Points', property: 'points' },
            { title: 'Tickets', property: 'tickets' },
            { title: 'Products', property: 'products' },
            { title: 'Selections', property: 'productSelections' },
          ]}
          sortColumn='Title'
          data={tableData}
        />
      </Sleeve>
    </AppShell>
  );
}
