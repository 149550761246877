import { useNavigate } from '@reach/router';
import React, { useState } from 'react';
import ProductService from '../../../hooks/ProductService';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import ProductForm from './ProductForm';

export default function ProductNewScreen() {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(p: { title: string; options: string[]; image?: File }) {
    if (!p.image) return;
    setIsLoading(true);
    const { url, key } = await ProductService.getImageUrl(p.image.name);
    await ProductService.uploadImage(url, p.image);
    await ProductService.create({
      title: p.title,
      options: p.options,
      imageUrl: `https://crispo-canada-rewards-images.s3.amazonaws.com/${key}`,
    });
    setIsLoading(false);
    nav(`/products`);
  }

  return (
    <AppShell>
      <Sleeve back title='Product New'>
        <ProductForm isLoading={isLoading} onSubmit={onSubmit}></ProductForm>
      </Sleeve>
    </AppShell>
  );
}
