import React, { useEffect } from 'react';
import { useState } from 'react';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput, CrispoInputClassName } from '../../lib/CrispoInput';
import CrispoLabel from '../../lib/CrispoLabel';
import { RewardDto } from '../Rewards/RewardDto';
import RewardService from '../Rewards/RewardService';
import { StatusDto } from './StatusDto';

interface StatusFormProps {
  status?: StatusDto;
  onSubmit?(status: StatusDto): void;
}

export default function StatusForm(props: StatusFormProps) {
  const [title, setTitle] = useState(props.status?.title || '');
  const [scansRequired, setScansRequired] = useState(props.status?.scansRequired || 0);
  const [scanModifier, setScanModifier] = useState(props.status?.scanModifier || 1);
  const [ticketRate, setTicketRate] = useState(props.status?.ticketRate || 1);
  const [rewards, setRewards] = useState<RewardDto[]>([]);
  const [reward, setReward] = useState(props.status?.reward || '');

  useEffect(() => {
    RewardService.list().then(setRewards);
  }, []);

  function onSubmit() {
    props.onSubmit &&
      props.onSubmit({
        title,
        scansRequired,
        scanModifier,
        ticketRate,
        reward,
      });
  }

  return (
    <CrispoCard>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className='flex'>
          <CrispoInput label='Title' onChange={(e) => setTitle(e.target.value)} value={title} />
          <div className='mx-3'></div>
          <CrispoInput
            label='Scans Required'
            type='number'
            onChange={(e) => setScansRequired(parseInt(e.target.value))}
            value={scansRequired}
          />
        </div>

        <div className='flex'>
          <CrispoInput
            label='Ticket Rate'
            type='number'
            onChange={(e) => setTicketRate(parseInt(e.target.value))}
            value={ticketRate}
          />
          <div className='mx-3'></div>
          <CrispoInput
            label='Scan Modifier'
            type='number'
            onChange={(e) => setScanModifier(parseInt(e.target.value))}
            value={scanModifier}
          />
        </div>

        <div className=''>
          <CrispoLabel>Reward</CrispoLabel>
          <br></br>
          <select className={CrispoInputClassName} onChange={(e) => setReward(e.target.value)} value={reward}>
            {rewards.map((r) => (
              <option key={r._id} value={r._id}>
                {r.title}
              </option>
            ))}
          </select>
        </div>
        <div className='mb-3'></div>
        <CrispoButton onClick={onSubmit} title='Submit'></CrispoButton>
      </form>
    </CrispoCard>
  );
}
