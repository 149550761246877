import { useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import CrispoCard from '../../lib/CrispoCard';
import Debug from '../../lib/Debug';
import { Sleeve } from '../../lib/Sleeve';
import RedeemedService, { IRedemption } from './RedeemedService';

interface GridProps {
  columns: number;
}

export default function RedeemedDetailsScreen() {
  const params = useParams();
  const [redemption, setRedemption] = useState<IRedemption>();

  useEffect(() => {
    RedeemedService.find(params.id).then((redemption) => {
      console.log(redemption);
      setRedemption(redemption);
    });
  }, [params.id]);

  if (!redemption) {
    return (
      <AppShell>
        <Sleeve back title='Redemption'>
          loading...
        </Sleeve>
      </AppShell>
    );
  }

  return (
    <AppShell>
      <Sleeve back title='Redemption'>
        <CrispoCard>
          <div className='grid grid-cols-2 text-sm'>
            <div className='text-xs font-semibold border-b'>Reward</div>
            <div className='text-xs font-semibold border-b'>Value</div>
            <div>Title</div>
            <div>{redemption.reward.title}</div>
            {redemption.code && (
              <>
                <div>Referral Code</div>
                <div className='font-semibold text-blue-700'>{redemption.code}</div>
              </>
            )}
            <div>Points</div>
            <div>{redemption.reward.points}</div>
            <div>Tickets</div>
            <div>{redemption.reward.tickets}</div>
          </div>

          <div className='mt-3'></div>
          <div className='grid grid-cols-2 text-sm'>
            <div className='text-xs font-semibold border-b'>Product</div>
            <div className='text-xs font-semibold border-b'>Option</div>
            {redemption.order.products.map((po) => (
              <>
                <div>{po.product.title}</div>
                <div>{po.option || 'None'}</div>
              </>
            ))}
          </div>

          <div className='mt-3'></div>
          <div className='grid grid-cols-3 text-sm'>
            <div className='text-xs font-semibold border-b'>Product Selection</div>
            <div className='text-xs font-semibold border-b'>Product</div>
            <div className='text-xs font-semibold border-b'>Option</div>
            {redemption.order.selections.map((s) => (
              <>
                <div>{s.productSelection.title}</div>
                <div>{s.product.product.title}</div>
                <div>{s.product.option}</div>
              </>
            ))}
          </div>
          {/* <Debug item={redemption} /> */}
        </CrispoCard>
      </Sleeve>
    </AppShell>
  );
}
