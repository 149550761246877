import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Sleeve } from '../../lib/Sleeve';
import { CrispointDay } from './CrispointDay';
import CrispointDayService from './CrispointDayService';
import clsx from 'clsx';
import CrispoCard from '../../lib/CrispoCard';

export default function CrispointDaysScreen() {
  const [days, setDays] = useState<CrispointDay[]>(CrispointDayService.state.list);

  useEffect(() => {
    CrispointDayService.list().then(setDays);
  }, []);

  function compareDates(a: Date, b: Date) {
    a.setHours(0, 0, 0, 0);
    b.setHours(0, 0, 0, 0);
    return a.toISOString() === b.toISOString();
  }

  const crispointDates = days.map((d) => new Date(d.date));
  crispointDates.forEach((d) => d.setHours(0, 0, 0, 0));

  async function onDateClick(date: Date) {
    const found = days.find((day) => compareDates(date, new Date(day.date)));

    if (found) {
      if (!(await CrispointDayService.remove(found._id))) {
        alert('unable to remove day');
      }
    } else {
      await CrispointDayService.create(date);
    }
    CrispointDayService.list().then(setDays);
  }

  function renderDay(day: Date) {
    day.setHours(0, 0, 0, 0);
    const date = day.getDate();
    const isSelected = crispointDates.find((d) => d.toISOString() === day.toISOString());
    return (
      <div
        className={clsx(
          isSelected ? 'bg-yellow-100 cursor-not-allowed' : 'bg-white hover:bg-yellow-50 cursor-pointer',
          'flex items-center justify-center w-16 h-16 text-black transition-colors rounded',
        )}
      >
        {date}
      </div>
    );
  }

  return (
    <AppShell>
      <Sleeve title='Crispoint Days'>
        <CrispoCard className='mx-auto'>
          <DayPicker
            renderDay={renderDay}
            className='max-w-xl p-3 mx-auto'
            selectedDays={days.map((d) => new Date(d.date))}
            onDayClick={onDateClick}
            modifiers={{ all: () => true }}
            modifiersStyles={{
              all: {
                background: 'none',
                border: 'none',
                outline: 'none',
                color: 'black',
                fontWeight: 'normal',
                margin: 0,
                padding: 0,
              },
            }}
          ></DayPicker>
        </CrispoCard>
        <p className='mx-auto mt-3 text-sm italic text-gray-600'>
          Click on a day to mark it as a Crispoint day. Click again to remove it
        </p>
      </Sleeve>
    </AppShell>
  );
}
