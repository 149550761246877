import { IProduct } from '../components/screens/Reedeemed/RedeemedService';
import api from './useAPI';

export default {
  cache: { list: [] },
  async create(product: IProduct) {
    return api.post('/products', product).then((res) => res.json());
  },

  async list() {
    return api
      .get('/products')
      .then((res) => res.json())
      .then((data) => {
        this.cache.list = data;
        return data;
      });
  },

  async find(id: string) {
    return api.get(`/products/${id}`).then((res) => res.json());
  },

  async getImageUrl(filename: string) {
    return api.get(`/products/image/upload-url?filename=${filename}`).then((res) => res.json());
  },

  async remove(id: string) {
    return api.del(`/products/${id}`).then((res) => res.ok);
  },

  async uploadImage(url: string, file: File) {
    return api.putFile(url, file);
  },

  async update(id: string, update: any) {
    return api.patch(`/products/${id}`, update).then((res) => res.json());
  },
};
