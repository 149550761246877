import React from 'react';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';

interface DirectionIconProps {
  direction?: number;
  size: string;
}
export function DirectionIcon(props: DirectionIconProps) {
  switch (props.direction) {
    case 1:
      return <MdArrowUpward className='ml-auto' size={props.size} />;
    case -1:
      return <MdArrowDownward className='ml-auto' size={props.size} />;
    default:
      return <MdArrowUpward className='ml-auto text-transparent' size={props.size} />;
  }
}
