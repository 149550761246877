import React, { useState, useEffect, useMemo } from 'react';
import { AppShell } from '../../lib/AppShell';
import { EmailForm } from './EmailForm';
import { EmailGroupList } from './EmailGroupList';
import { Sleeve } from '../../lib/Sleeve';
import { useEmail } from '../../../hooks/useEmail';
import { CrispoButton } from '../../lib/CrispoButton';
import { IGroup } from '../../../dto/IGroups';
import GroupService from '../../../hooks/GroupService';
import CrispoCard from '../../lib/CrispoCard';

const MAX_ATTACHMENT_SIZE = 9437184;
interface Props {}

export function EmailScreen(props: Props) {
  const { sendEmail } = useEmail();
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<IGroup[]>([]);
  const [filter, setFilter] = useState('');
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [sendTo, setSendTo] = useState('');
  const [attachments, setAttachments] = useState<FileList>();
  const [attachmentSize, setAttachmentSize] = useState(0);

  useEffect(() => {
    GroupService.list().then(setGroups);
  }, []);

  async function getEmailList(sendMethod: string) {
    switch (sendMethod.toLowerCase()) {
      case 'selected groups': {
        const res = await GroupService.getUniqueMembers(selectedGroups.map((g) => g._id));
        return res?.users.map((u: { _id: string; username: string }) => u.username);
      }
      case 'everyone except selected groups': {
        const groupIds = selectedGroups.map((g) => g._id);

        const [allGroup, uniqueMembers] = await Promise.all([
          GroupService.fetchGroup('all'),
          GroupService.getUniqueMembers(groupIds),
        ]);

        return allGroup?.users
          .filter((allGroupUser) =>
            uniqueMembers?.users.find((uniqueUser) => uniqueUser._id === allGroupUser._id) ? false : true,
          )
          .map((u) => u.username);
      }
      default:
        return undefined;
    }
  }

  async function submitHandler() {
    const emailList = await getEmailList(sendTo);
    if (!emailList || emailList.length === 0) {
      return alert("No groups selected or 'Send To' option invalid");
    }
    setIsSendingEmail(true);
    const res = await sendEmail(emailList, subject, content, attachments);
    setIsSendingEmail(false);
    if (!res.ok) {
      alert('Error sending email');
    } else {
      alert('Email succesfully sent');
    }
  }

  function onGroupClick(group: IGroup) {
    if (selectedGroups.includes(group)) {
      setSelectedGroups(selectedGroups.filter((g) => g._id !== group._id));
    } else {
      setSelectedGroups([...selectedGroups, group]);
    }
  }

  function onSelectAllGroups() {
    setSelectedGroups([...groups]);
  }

  function onSelectNoneGroups() {
    setSelectedGroups([]);
  }

  const fileLimitExceeded = useMemo(() => attachmentSize >= MAX_ATTACHMENT_SIZE, [attachmentSize]);

  const subjectChangeHandler = (subject: string) => setSubject(subject);

  const sendToChangeHandler = (sendTo: string) => setSendTo(sendTo);

  const contentChangeHandler = (content: string) => setContent(content);

  const filesChangeHandler = async (fileList: FileList) => {
    let totalSize = 0;
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      if (file) totalSize += file?.size;
    }
    setAttachmentSize(totalSize);
    setAttachments(fileList);
  };

  return (
    <AppShell>
      <Sleeve title='Email'>
        <CrispoCard>
          <div className='flex'>
            <div className='flex-1'>
              <EmailForm
                onContentChange={contentChangeHandler}
                onSubjectChange={subjectChangeHandler}
                onSendToChange={sendToChangeHandler}
                onFilesChange={filesChangeHandler}
              />
              <p className='mt-1 text-red-600'>{fileLimitExceeded && 'file size too large'}</p>
              <div className='flex'>
                <div className='mt-3 ml-auto'>
                  <CrispoButton
                    isLoading={isSendingEmail}
                    title='Send'
                    onClick={submitHandler}
                    disabled={fileLimitExceeded}
                  />
                </div>
              </div>
            </div>
            <div className='px-6' style={{ minWidth: '25%' }}>
              <EmailGroupList
                groups={groups}
                selectedGroups={selectedGroups}
                onSelectAll={onSelectAllGroups}
                onSelectNone={onSelectNoneGroups}
                onFilterChange={setFilter}
                filter={filter}
                onGroupClick={onGroupClick}
              />
            </div>
          </div>
        </CrispoCard>
      </Sleeve>
    </AppShell>
  );
}
