import { useNavigate } from '@reach/router';
import React from 'react';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import ProductGroupForm from './ProductGroupForm';
import ProductGroupService from './ProductGroupService';

export default function ProductGroupNewScreen() {
  const nav = useNavigate();
  function onSubmit(title: string, products: string[]) {
    ProductGroupService.create({ title, products }).then(() => nav('/product-groups'));
  }
  return (
    <AppShell>
      <Sleeve back title='Product Group New'>
        <ProductGroupForm onSubmit={onSubmit} />
      </Sleeve>
    </AppShell>
  );
}
