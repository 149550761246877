import React, { ReactNode, useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { SelectionItem, ItemSelectionList } from './ItemSelectionList';

interface ItemSelectionProps {
  onAddItems?(items: SelectionItem[]): void;
  onRemoveItems?(items: SelectionItem[]): void;
  leftItems: SelectionItem[];
  rightItems: SelectionItem[];
  leftTitle?: string;
  rightTitle?: string;
}

export default function ItemSelection(props: ItemSelectionProps) {
  const [leftSelections, setLeftSelections] = useState<string[]>([]);
  const [rightSelections, setRightSelections] = useState<string[]>([]);

  function clearRightSelections() {
    setRightSelections([]);
  }

  function clearLeftSelections() {
    setLeftSelections([]);
  }

  return (
    <div className='flex justify-between p-3 rounded'>
      <div>
        <ItemSelectionList
          selectedIds={leftSelections}
          onSelectNone={() => setLeftSelections([])}
          onSelectAll={() => setLeftSelections(props.leftItems.map((i) => i._id))}
          onItemSelect={function onItemCheck(checked, item) {
            if (checked) {
              leftSelections?.push(item._id);
              setLeftSelections([...leftSelections]);
            } else {
              setLeftSelections(leftSelections.filter((sel) => item._id !== sel));
            }
          }}
          items={props.leftItems}
          title={props.leftTitle}
        />
      </div>
      <div className='flex flex-col justify-center w-1/6 mx-6 h-100'>
        <ItemActionButton
          color='green'
          onClick={() => {
            if (!!props.rightItems.find((s) => leftSelections.includes(s._id))) {
              return;
            }
            props.onAddItems && props.onAddItems(props.leftItems.filter((i) => leftSelections.includes(i._id)));
            clearLeftSelections();
          }}
        >
          <MdArrowForward size={24} className='mx-auto' />
        </ItemActionButton>
        <div className='h-1/12'></div>
        <ItemActionButton
          color='red'
          onClick={() => {
            if (!!props.leftItems.find((s) => rightSelections.includes(s._id))) {
              return;
            }

            props.onRemoveItems && props.onRemoveItems(props.rightItems.filter((i) => rightSelections.includes(i._id)));
            clearRightSelections();
          }}
        >
          <MdArrowBack size={24} className='mx-auto' />
        </ItemActionButton>
      </div>

      <ItemSelectionList
        selectedIds={rightSelections}
        onSelectAll={() => setRightSelections(props.rightItems.map((i) => i._id))}
        onSelectNone={() => setRightSelections([])}
        onItemSelect={function onItemCheck(checked, item) {
          if (checked) {
            rightSelections?.push(item._id);
            setRightSelections([...rightSelections]);
          } else {
            setRightSelections(rightSelections.filter((sel) => item._id !== sel));
          }
        }}
        items={props.rightItems}
        title={props.rightTitle}
      />
    </div>
  );
}

interface ItemActionButtonProps {
  children?: ReactNode;
  onClick?: () => void;
  color: string;
}

function ItemActionButton({ onClick, children, color }: ItemActionButtonProps) {
  return (
    <button
      onClick={onClick}
      className={`px-3 py-2 text-gray-800 transition-colors duration-150 bg-${color}-200 rounded shadow hover:bg-${color}-300 hover:text-gray-900`}
    >
      {children}
    </button>
  );
}
