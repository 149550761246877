import api from '../../../hooks/useAPI';

const OverviewService = {
  state: {
    stats: { users: 0, scans: 0, rewards: 0 },
  },
  async totals() {
    return api
      .get('/totals/stats')
      .then((res) => res.json())
      .then((data) => {
        this.state.stats = data;
        return data;
      });
  },

  async scanChart(days: number) {
    return api.get(`/charts/scans?days=${days}`).then((res) => res.json());
  },

  async usersChart(days: number) {
    return api.get(`/charts/users?days=${days}`).then((res) => res.json());
  },
};

export default OverviewService;
