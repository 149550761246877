import React, { useEffect, useState, useCallback } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { Sleeve } from '../../lib/Sleeve';
import { IQr } from '../../../dto/IQr';
import qs from 'query-string';
import QRCode from 'qrcode.react';
import { api } from '../../../services/api';
import { BeatLoader } from 'react-spinners';

export function QrPrintPreview(props: RouteComponentProps) {
  const [qrs, setQrs] = useState<IQr[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [printTemplate, setPrintTemplate] = useState('template');
  const location = useLocation();


  const fetchCodes = useCallback(async () => {
    setIsLoading(true);
    const search = new URLSearchParams(location.search);
    const limit = search.get('limit');
    const skip = search.get('skip');
    const template = search.get('template');
    if (limit && skip && template) {
      setPrintTemplate(template!);
      const query = qs.stringify({ limit, skip });
      try {
        const res = await api.get(`/qr?${query}`);
        setQrs(res.data.codes);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCodes();
  }, [fetchCodes]);

  return (
    <Sleeve
      back
      title='QR Slips Print Preview'>
      {isLoading || printTemplate === 'template' ? (
        <div className='flex items-center justify-center w-full h-full'>
          <BeatLoader size='16px' color='#0166ae' />
        </div>
      ) : (
        <>
          <div className='printable-container'>
            <div className='scrollable-content w-full'>
              {printTemplate === 'large' ? (qrs?.map((qr: IQr) => {
                return (
                  <div className={'mx-auto mb-10 p-3 flex justify-center align-center bg-crispo-slip w-qrsl-width h-qrsl-height'} key={qr._id}>
                    <div className='px-4 w-full bg-white rounded-lg'>
                      <div className='text-center'>
                        <h1 className='text-crispo-slip text-xl anton-regular my-3 text-qrsl-heading'>Crisp-Air Rewards App!</h1>
                        <p className='font-bold text-crispo-dark text-qrsl-body'>Scan the QR code on the Crisp-Air Rewards app to earn points towards fantastic prizes & your chance to win our extraordinary ATV Grand prize! The more you scan the more chances you have to win! For complete details about the Crisp-Air Rewards App visit us at <span className='text-crispo-slip'>www.crispocanada.com/rewards</span></p>

                        <div className='flex justify-center my-4'>
                          <div className='w-1/3 flex flex-wrap justify-center'>
                            <img src={require('../../../assets/atv-flipped.png')} alt='ATV' width='100%' />
                            <p className='font-bold text-crispo-dark text-qrsl-caption'>ATV NOT EXACTLY AS SHOWN</p>
                          </div>
                          <div className='w-1/3'>
                            <h2 className='text-qrsl-guid'>{qr.code}</h2>
                            <QRCode value={qr.code} size={85} className='mx-auto mt-1' />
                          </div>
                          <div className='w-1/3 flex flex-wrap justify-center'>
                            <img src={require('../../../assets/atv.png')} alt='ATV' width='100%' />
                            <p className='font-bold text-crispo-dark text-qrsl-caption'>VTT NON EXACTEMENT COMME INDIQUÉ</p>
                          </div>
                        </div>

                        <p className='font-bold text-crispo-dark text-qrsl-body'>Scannez le code QR sur l'application Crisp-Air Rewards pour gagner des points en échange de fantastiques recompenses & votre chance de gagner notre extraordinaire grand prix VTT! Plus vous scannez, plus vos chances de gagner! Pour tous les détails sur l'application Crisp-Air Rewards, visitez notre site <span className='text-crispo-slip'>www.crispocanada.com/rewards</span></p>
                        <div className='flex justify-center items-center my-3'>
                          <p className='text-crispo-dark text-lg mx-4 anton-regular'>#Ask4TheBlueBox</p>
                          <img className='mx-1 h-qrsl-store-height w-qrsl-store-width' src={require('../../../assets/available-apple.png')} alt='available Apple' />
                          <img className='mx-1 h-qrsl-store-height w-qrsl-store-width' src={require('../../../assets/available-android.png')} alt='available Android' ></img>
                          <p className='text-crispo-dark text-lg mx-4 anton-regular'>@CrispoCanada</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })) : (qrs?.map((qr: IQr) => {
                return (
                  <div className='mx-auto my-1 p-2 flex justify-center align-center h-qrss-height w-qrss-width bg-crispo-slip' key={qr._id}>
                    <div className='px-3 w-full bg-white rounded-lg'>
                      <div className='text-center'>
                        <h1 className='text-crispo-slip text-lg anton-regular'>Crisp-Air Rewards App!</h1>
                        <p className='font-bold text-crispo-dark leading-tight text-qrss-body'>Scan the QR code on the Crisp-Air Rewards app to earn points towards fantastic prizes & your chance to win our extraordinary ATV Grand prize! The more you scan the more chances you have to win! For complete details about the Crisp-Air Rewards App visit us at <span className='text-crispo-slip'>www.crispocanada.com/rewards</span></p>

                        <div className='flex justify-center my-1'>
                          <div className='w-1/3 pl-8 flex flex-wrap justify-center'>
                            <img src={require('../../../assets/atv-flipped.png')} alt='ATV' width='125' />
                            <p className='font-bold text-xs text-crispo-dark leading-tight text-qrss-caption'>ATV NOT EXACTLY AS SHOWN</p>
                          </div>
                          <div className='w-1/3'>
                            <h2 className='leading-tight text-qrss-guid'>{qr.code}</h2>
                            <QRCode value={qr.code} size={55} className='mx-auto mt-1' />
                          </div>
                          <div className='w-1/3 pr-8 flex flex-wrap justify-center'>
                            <img src={require('../../../assets/atv.png')} alt='ATV' width='125' />
                            <p className='font-bold text-xs text-crispo-dark leading-tight text-qrss-caption'>VTT NON EXACTEMENT COMME INDIQUÉ</p>
                          </div>
                        </div>

                        <p className='font-bold text-crispo-dark leading-tight text-qrss-body'>Scannez le code QR sur l'application Crisp-Air Rewards pour gagner des points en échange de fantastiques recompenses & votre chance de gagner notre extraordinaire grand prix VTT! Plus vous scannez, plus vos chances de gagner! Pour tous les détails sur l'application Crisp-Air Rewards, visitez notre site <span className='text-crispo-slip'>www.crispocanada.com/rewards</span></p>
                        <div className='flex justify-center items-center my-1'>
                          <p className='text-crispo-dark text-xs mx-3 anton-regular'>#Ask4TheBlueBox</p>
                          <img className='mx-1 h-qrss-store-height w-qrss-store-width' src={require('../../../assets/available-apple.png')} alt='available Apple' />
                          <img className='mx-1 h-qrss-store-height w-qrss-store-width' src={require('../../../assets/available-android.png')} alt='available Android' ></img>
                          <p className='text-crispo-dark text-xs mx-3 anton-regular'>@CrispoCanada</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }))}
            </div>
          </div>
        </>
      )}
    </Sleeve>
  );
}