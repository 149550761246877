import React, { useState } from 'react';
import logo from '../../../assets/crispo-logo.png';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput } from '../../lib/CrispoInput';

export interface LoginFormProps {
  onLogin: (username: string, password: string) => any;
  error: string;
}
export function LoginForm(props: LoginFormProps) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  function login(e: any) {
    e.preventDefault();
    props.onLogin(username, password);
  }

  return (
    <CrispoCard>
      <div className='flex items-center mb-5'>
        <img src={logo} alt='crispo-canada-logo' className='h-24' />
        <p className='ml-4 text-3xl text-crispo'>Crispo-Rewards</p>
      </div>
      <form onSubmit={login}>
        <div className='my-auto mb-4'>
          <CrispoInput
            name='username'
            onChange={(e) => setUsername(e.target.value)}
            className='mb-3'
            placeholder='email@example.com'
            label='Email'
          />
          <CrispoInput
            name='password'
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            placeholder='*******'
            label='Password'
          />
          <p className='mt-3 text-sm italic text-red-500'>{props.error}</p>
        </div>
        <div className='flex items-center justify-end'>
          <CrispoButton block title='Sign In' type='submit' />
        </div>
        <div className='mt-3 text-xs text-right text-gray-500'>
          Created by{' '}
          <a className='underline text-crispo' href='https://i3inc.ca'>
            i3inc
          </a>
        </div>
      </form>
    </CrispoCard>
  );
}
