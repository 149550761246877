import clsx from 'clsx';
import React, { ReactNode } from 'react';
import CrispoLabel from './CrispoLabel';

export interface CrispoInputProps extends Input {
  label?: string;
  hint?: string;
  icon?: ReactNode;
}

export const CrispoInputClassName =
  'flex-1 h-12 px-3 bg-white border rounded outline-none focus:border-2 focus:border-crispo-light';

type Input = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const CrispoInput = ({ hint, readOnly, icon, label, className, ...props }: CrispoInputProps) => {
  return (
    <div className={`w-full ${className}`}>
      {label && (
        <>
          <CrispoLabel htmlFor={props.name}>{label}</CrispoLabel>
          <br></br>
        </>
      )}
      <span className='flex bg-gray-200 rounded'>
        <input
          readOnly={readOnly}
          className={clsx(
            readOnly
              ? 'bg-gray-100 select-none cursor-not-allowed'
              : 'bg-white focus:border-crispo-light focus:border-2',
            'flex-1 h-12 px-3 border rounded outline-none ',
          )}
          {...props}
        />
        {icon}
      </span>
      <p className='text-xs italic text-gray-500'>{hint}</p>
    </div>
  );
};
