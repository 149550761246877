import api from '../../../hooks/useAPI';
import { CrispointDay } from './CrispointDay';

export default {
  url: '/crispoints-day',
  state: { list: [] },
  async create(date: Date) {
    return api.post(this.url, { date }).then((res) => res.json());
  },

  async list() {
    return api
      .get(this.url)
      .then((res) => res.json())
      .then((data) => {
        this.state.list = data;
        return data;
      });
  },

  async find(id: string) {
    return api.get(`${this.url}/${id}`).then((res) => res.json());
  },

  async remove(id: string) {
    return api.del(`${this.url}/${id}`).then((res) => res.ok);
  },

  async update(id: string, update: CrispointDay) {
    return api.patch(`${this.url}/${id}`, update).then((res) => res.json());
  },
};
