import clsx from 'clsx';
import React from 'react';

export type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export interface CardProps extends DivProps {
  dense?: boolean;
}

export default function CrispoCard({ dense, className, children, ...props }: CardProps) {
  return (
    <div className={clsx(!dense && 'p-3', 'bg-white rounded border', className)} {...props}>
      {children}
    </div>
  );
}
