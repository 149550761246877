import React, { useEffect, useState } from 'react';
import CrispoDatePicker from '../../lib/CrispoDatePicker';
import CrispoLabel from '../../lib/CrispoLabel';
import ContestService, { SubmissionDto } from './ContestService';
import SubmissionItem from './SubmissionItem';

export interface PhotoSubmissionGridProps {
  contest: string;
  onItemSelect(submission: SubmissionDto): void;
}
export default function PhotoSubmissionGrid(props: PhotoSubmissionGridProps) {
  const [submissions, setSubmissions] = useState<SubmissionDto[]>([]);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  useEffect(() => {
    ContestService.getSubmissions(props.contest, from, to).then(setSubmissions);
  }, [props.contest, from, to]);

  return (
    <div>
      <div className='flex items-end'>
        <CrispoDatePicker
          label='From'
          selected={new Date(from)}
          onChange={(date: Date) => {
            setFrom(date);
          }}
        />
        <div className='mx-3'></div>
        <CrispoDatePicker
          label='To'
          selected={new Date(to)}
          onChange={(date: Date) => {
            setTo(date);
          }}
        />
      </div>
      <div className='mb-3'></div>
      <CrispoLabel>Submissions</CrispoLabel>
      <div className='grid grid-cols-4'>
        {submissions.length > 0 ? (
          submissions.map((ps) => {
            return <SubmissionItem submission={ps} onClick={() => props.onItemSelect(ps)} key={ps.key} />;
          })
        ) : (
          <p className='my-3 text-sm italic'>No Submissions Found</p>
        )}
      </div>
    </div>
  );
}
