import React, { useEffect, useState } from 'react';
import { CrispoButton } from '../../lib/CrispoButton';
import { CrispoInput, CrispoInputClassName } from '../../lib/CrispoInput';
import CrispoLabel from '../../lib/CrispoLabel';
import { RewardDto } from '../Rewards/RewardDto';
import RewardService from '../Rewards/RewardService';
import { ContestDto, ContestRawDto, ContestType } from './ContestService';

export interface ContestFormProps {
  contest?: ContestDto;
  onSubmit(contest: ContestRawDto): void;
}
export default function ContestForm(props: ContestFormProps) {
  const [title, setTitle] = useState(props.contest?.title || '');
  const [reward, setReward] = useState(props.contest?.reward._id || '');
  const [rewards, setRewards] = useState<RewardDto[]>([]);

  useEffect(() => {
    RewardService.list().then(setRewards);
  }, []);

  function onSubmit() {
    props.onSubmit({
      reward,
      title,
      type: props.contest?.type || ContestType.ScanDraw,
    });
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className='flex items-end mb-3'>
        <CrispoInput label='Title' value={title} onChange={(e) => setTitle(e.target.value)} />
        <div className='mx-3'></div>
        <div>
          <CrispoLabel>Reward</CrispoLabel>
          <br></br>
          <select className={CrispoInputClassName} onChange={(e) => setReward(e.target.value)} value={reward}>
            {rewards.map((reward) => (
              <option key={reward._id!} value={reward._id}>
                {reward.title}
              </option>
            ))}
          </select>
        </div>

        <div className='mx-3'></div>
        <CrispoInput readOnly label='Type' value={props.contest?.type} />
        <div className='mx-3'></div>
      </div>
      <CrispoButton className='h-12' secondary title='Save' onClick={onSubmit} />
    </form>
  );
}
