import React from 'react';
import { IGroup } from '../../../dto/IGroups';
import CrispoCard from '../../lib/CrispoCard';
import { CrispoInput } from '../../lib/CrispoInput';
import { SelectOptions } from '../../lib/SelectOptions';

interface Props {
  onSelectAll: () => any;
  onSelectNone: () => any;
  onFilterChange: (filter: string) => any;
  onGroupClick: (recepient: IGroup) => any;
  filter: string;
  groups: IGroup[];
  selectedGroups: IGroup[];
}
export function EmailGroupList(props: Props) {
  const { onSelectAll, onSelectNone, onFilterChange, onGroupClick, groups, filter } = props;

  return (
    <div>
      <CrispoInput
        label='Groups'
        type='search'
        onChange={(e) => onFilterChange(e.target.value)}
        placeholder='Search for groups...'
      />
      <div className='mb-1'></div>
      <SelectOptions onSelectAll={onSelectAll} onSelectNone={onSelectNone} className='ml-auto' />
      <div className='mb-1'></div>
      <CrispoCard className='' style={{ maxHeight: '80vh' }}>
        {groups &&
          groups
            .filter((group) => new RegExp(filter, 'gi').test(group.name))
            .map((group) => {
              return (
                <div
                  key={group._id}
                  className={'flex cursor-pointer hover:bg-blue-100 min-w-max-content rounded-lg p-1'}
                  onClick={(e) => onGroupClick(group)}
                >
                  <input
                    type='checkbox'
                    className='my-auto mr-3 cursor-pointer'
                    readOnly
                    checked={props.selectedGroups.includes(group)}
                  />
                  <div key={group.name} className='my-auto text-sm text-gray-500 select-none'>
                    {group.name}
                  </div>
                </div>
              );
            })}
      </CrispoCard>
    </div>
  );
}
