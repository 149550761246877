import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { TotalStats } from './TotalStats';
import { AppShell } from '../../lib/AppShell';
import { UsersChart } from './UsersChart';
import { ScansChart } from './ScansChart';
import { PurchasesChart } from './PurchasesChart';
import { Sleeve } from '../../lib/Sleeve';
import OverviewService from './OverviewService';

interface OverviewScreenProps extends RouteComponentProps {}

export function OverviewScreen(_: OverviewScreenProps) {
  const [stats, setStats] = useState(OverviewService.state.stats);

  useEffect(() => {
    OverviewService.totals().then(setStats);
  }, []);

  return (
    <AppShell>
      <Sleeve title='Overview'>
        {stats && <TotalStats users={stats.users} rewards={stats.rewards} scans={stats.scans} />}
        <div className='mb-3'></div>
        <UsersChart />
        <div className='mb-3'></div>
        <ScansChart />
        <div className='mb-3'></div>
        <PurchasesChart />
      </Sleeve>
    </AppShell>
  );
}
