import clsx from 'clsx';
import React from 'react';
import { SortTableColumnHeader } from './SortTableColumnHeader';

type PropertyAccessor = (item: any) => string;
export interface ColumnData {
  title: string;
  property?: string | PropertyAccessor;
  hideTitle?: boolean;
  component?: (props: { item: any }) => any;
}

export type Direction = 0 | 1 | -1;

interface SortTableProps {
  direction?: Direction;
  sortColumn: string;
  id?: string;
  forceDirection?: boolean;
  columns: ColumnData[];
  data: { id: string | number; [key: string]: any }[];
  onItemClick?: (obj: any) => any;
  onHeaderClick?: (name: ColumnData, nextDirection: Direction) => any;
}

export const SortTable: React.FC<SortTableProps> = (props) => {
  const { direction = 0, forceDirection, sortColumn, data: tableData } = props;

  function getNextDirection(direction: Direction) {
    switch (direction) {
      case 1:
        return -1;
      case -1:
        return forceDirection ? 1 : 0;
      default:
        return 1;
    }
  }

  function getRenderItem(columnData: ColumnData, item: any) {
    if (columnData.component) {
      const { component: Comp } = columnData;
      return Comp({ item });
    } else if (columnData.property) {
      if (typeof columnData.property === 'function') {
        return columnData.property(item);
      }
      return item[columnData.property];
    } else {
      return null;
    }
  }

  const onColumnHeaderClick = (name: ColumnData) => {
    props.onHeaderClick && props.onHeaderClick(name, getNextDirection(direction));
  };

  return (
    <div className='overflow-hidden align-middle rounded-t-md'>
      <table className='w-full'>
        <thead>
          <tr
            className='sticky top-0 w-full'
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${props.columns.length}, 1fr)`,
            }}
          >
            {props.columns.map((column) => (
              <SortTableColumnHeader
                key={column.title}
                title={column.title}
                hideTitle={column.hideTitle}
                onClick={
                  onColumnHeaderClick
                    ? () => {
                        onColumnHeaderClick(column);
                      }
                    : undefined
                }
                direction={sortColumn.toLowerCase() === column.title.toLowerCase() ? direction : 0}
              />
            ))}
          </tr>
        </thead>
        <tbody className=''>
          {tableData.map((item, itemIndex, items) => (
            <tr
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${props.columns.length}, 1fr)`,
              }}
              key={item.id || itemIndex}
              className={clsx(
                'w-full cursor-pointer hover:bg-gray-100',
                itemIndex === items.length - 1 && 'border-b',
                itemIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50',
              )}
              onClick={(e) => {
                props.onItemClick && props.onItemClick(item);
              }}
            >
              {props.columns.map((columnData, columnIndex) => {
                let prop = columnData.property;
                if (typeof columnData.property === 'function') {
                  prop = columnData.property(item);
                }

                return (
                  <td
                    key={(prop as any) || columnIndex}
                    className={clsx(
                      columnIndex === 0 && 'border-l',
                      `px-6 border-r py-4 text-sm text-left text-gray-500 leading-5 overflow-hidden whitespace-nowrap overflow-ellipsis`,
                    )}
                  >
                    {getRenderItem(columnData, item)}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
