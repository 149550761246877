import React, { useState } from 'react';

export const AuthContext = React.createContext({
  isAuthenticated: false,
  setAuthenticated: (value: boolean) => {},
  user: null,
  setUser: (value: any) => {},
});

interface AuthContextProviderProps {
  children?: any;
}
export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  return (
    <AuthContext.Provider value={{ isAuthenticated: authenticated, setAuthenticated, user, setUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};
