import { RouteComponentProps, useNavigate, useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import ProductService from '../../../hooks/ProductService';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { IProduct } from '../Reedeemed/RedeemedService';
import ProductForm from './ProductForm';

interface ProductEditScreenProps extends RouteComponentProps {
  id: string;
}
export default function ProductEditScreen(props: ProductEditScreenProps) {
  const params = useParams();
  const [product, setProduct] = useState<IProduct>();
  const [isLoading, setIsLoading] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    ProductService.find(params.id).then(setProduct);
  }, [params.id]);

  async function onSubmit(p: { title: string; options: string[]; image?: File }) {
    setIsLoading(true);
    if (!p.image) {
      ProductService.update(params.id, { title: p.title, options: p.options, imageUrl: product?.imageUrl })
        .then(() => nav('/products'))
        .catch((err) => {
          console.error(err);
          alert('Unable to update product');
        });
    } else {
      const { url, key } = await ProductService.getImageUrl(p.image!.name);
      await ProductService.uploadImage(url, p.image);

      ProductService.update(params.id, {
        title: p.title,
        options: p.options,
        imageUrl: `https://crispo-canada-rewards-images.s3.amazonaws.com/${key}`,
      })
        .then(() => nav('/products'))
        .catch((err) => {
          console.error(err);
          alert('Unable to update product');
        });
    }

    setIsLoading(false);
  }

  return (
    <AppShell>
      <Sleeve back title={'Product Edit'}>
        <ProductForm isLoading={isLoading} product={product} onSubmit={onSubmit} />
      </Sleeve>
    </AppShell>
  );
}
