import { IUser } from '../../../dto/IUser';
import api from '../../../hooks/useAPI';
import * as qs from 'query-string';
import { IUserDetails } from '../../../dto/IUserDetails';

export default {
  url: '/users',
  state: {
    query: { users: [], total: 0 },
  },

  async setBonus(id: string, points: number, tickets: number) {
    return api.put(this.url + `/${id}/bonus`, { user_id: id, points, tickets }).then((res) => res.ok);
  },

  async setAdminRole(id: string, admin: boolean) {
    return api.put(this.url + '/admin', { user_id: id, admin }).then((res) => res.ok);
  },

  async updatePassword(id: string, password: string) {
    return api.put(this.url + `/${id}/password-reset`, { password }).then((res) => res.ok);
  },

  async getDetails(id: string): Promise<IUserDetails> {
    return api.get(this.url + `/${id}/detail`).then((res) => res.json());
  },

  async query(
    search: string,
    sort: string,
    direction: number,
    page: number,
    limit: number,
  ): Promise<{ users: IUser[]; total: number }> {
    if (sort === 'signUpDate') sort = '_id';
    const query = qs.stringify(
      { search, sort, direction, limit, page },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    );

    return api
      .get(this.url + `?${query}`)
      .then((res) => res.json())
      .then((data) => {
        this.state.query = data;
        return data;
      });
  },

  async list(): Promise<IUser[]> {
    return api.get(this.url).then((res) => res.json());
  },

  async find(id: string): Promise<IUser> {
    return api.get(`${this.url}/${id}`).then((res) => res.json());
  },

  async remove(id: string) {
    return api.del(`${this.url}/${id}`).then((res) => res.ok);
  },

  async update(id: string, update: any) {
    return api.patch(`${this.url}/${id}`, update).then((res) => res.json());
  },
};
