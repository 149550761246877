import { useAPI } from './useAPI';
import { BACKEND_URL } from '../config';
import { useCallback } from 'react';

export function useEmail() {
  const api = useAPI(BACKEND_URL);

  const getEmailUserList = useCallback(async () => {
    const res = await api.get('/email/users');
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      return null;
    }
  }, [api]);

  const sendEmail = useCallback(
    async (to: string[], subject: string, content: string, attachments?: FileList) => {
      const fd = new FormData();
      fd.append('to', to.join(','));
      fd.append('subject', subject);
      fd.append('content', content);
      if (attachments) {
        for (let i = 0; i < attachments.length; i++) {
          const file = attachments[i];
          fd.append('attachments', file);
        }
      }
      const res = await api.postFormData('/email', fd);
      return res;
    },
    [api],
  );

  return { sendEmail, getEmailUserList };
}
