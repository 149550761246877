import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { CrispoButton } from '../../lib/CrispoButton';
import { Sleeve } from '../../lib/Sleeve';
import { SortTable } from '../../lib/SortTable';
import ProductGroupService, { IProductGroup } from './ProductGroupService';

export default function ProductGroupScreen() {
  const [productGroups, setProductGroups] = useState<IProductGroup[]>(ProductGroupService.cache.list);

  useEffect(() => {
    ProductGroupService.list().then(setProductGroups);
  }, []);

  const nav = useNavigate();

  return (
    <AppShell>
      <Sleeve
        title='Product Groups'
        action={
          <div className='ml-3'>
            <CrispoButton secondary title='New' onClick={() => nav('/product-groups/new')} />
          </div>
        }
      >
        <SortTable
          onItemClick={(i) => nav(`/product-groups/${i.id}/edit`)}
          direction={0}
          sortColumn={'title'}
          columns={[
            { title: 'Title', property: 'title' },
            { title: 'products', property: 'products' },
          ]}
          data={productGroups.map((pg) => ({
            id: pg._id,
            title: pg.title,
            products: pg.products.map((p) => p.title).join(', '),
          }))}
        />
      </Sleeve>
    </AppShell>
  );
}
