import React, { useState } from 'react';

export const ChartContext = React.createContext({
  users: [] as any[],
  setUsers: (users: any) => {},
  userDays: 30,
  setUserDays: (days: number) => {},
  scans: [] as any[],
  setScans: (scans: any) => {},
  scanDays: 30,
  setScanDays: (days: number) => {},
  purchases: [] as any[],
  setPurchases: (purchases: any) => {},
  purchaseDays: 30,
  setPurchaseDays: (days: number) => {},
});

interface Props {
  children?: any;
}
export const ChartContextProvider = (props: Props) => {
  const [users, setUsers] = useState([]);
  const [userDays, setUserDays] = useState(30);
  const [scans, setScans] = useState([]);
  const [scanDays, setScanDays] = useState(30);
  const [purchases, setPurchases] = useState([]);
  const [purchaseDays, setPurchaseDays] = useState(30);
  return (
    <ChartContext.Provider
      value={{
        users,
        setUsers,
        userDays,
        setUserDays,
        scans,
        setScans,
        scanDays,
        setScanDays,
        purchases,
        purchaseDays,
        setPurchaseDays,
        setPurchases,
      }}
    >
      {props.children}
    </ChartContext.Provider>
  );
};
