import React, { useState } from 'react';
import { CrispoSearch } from './CrispoSearch';
import { SelectOptions } from './SelectOptions';
import clsx from 'clsx';

export type SelectionItem<T = any> = {
  _id: string;
  text: string;
  item?: T;
};

export interface ItemSelectionListProps {
  title?: string;
  items?: SelectionItem[];
  onSelectAll?: () => void;
  onSelectNone?: () => void;
  selectedIds?: string[];
  onItemSelect?: (checked: boolean, item: SelectionItem) => any;
}

export function ItemSelectionList(props: ItemSelectionListProps) {
  const [search, setSearch] = useState('');

  function isItemSelected(item: SelectionItem) {
    return props.selectedIds && props.selectedIds.find((id) => id === item._id) ? true : false;
  }

  return (
    <div>
      <h1 className='flex-1 px-6 py-4 text-sm font-medium text-white uppercase rounded-t-lg bg-crispo'>
        {props.title}
      </h1>
      <div className='border'>
        <div className='p-2 bg-white'>
          <CrispoSearch
            placeholder='Search'
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <div className='flex justify-end w-full my-1'>
            <SelectOptions onSelectAll={props.onSelectAll} onSelectNone={props.onSelectNone} />
          </div>
        </div>

        <ul className='overflow-y-auto' style={{ maxHeight: '50vh' }}>
          {props.items &&
            props.items
              .filter((item) => item.text.match(search))
              .map((item, i) => {
                const isSelected = isItemSelected(item);
                const isEven = i % 2 === 0;

                return (
                  <li
                    onClick={() => {
                      props.onItemSelect && props.onItemSelect(!isSelected, item);
                    }}
                    key={item._id}
                    className={clsx(
                      isSelected
                        ? 'bg-yellow-100'
                        : isEven
                        ? 'bg-gray-50 hover:bg-gray-200'
                        : 'bg-white hover:bg-gray-200',
                      `flex p-3 cursor-pointer`,
                    )}
                  >
                    <p className='text-sm select-none'>{item.text}</p>
                    <input
                      type='checkbox'
                      className='ml-auto mr-3 cursor-pointer'
                      readOnly={true}
                      checked={isItemSelected(item)}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onItemSelect && props.onItemSelect(!isSelected, item);
                      }}
                    />
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
}
