import React, { useEffect, useMemo, useState } from 'react';
import { AppShell } from '../../lib/AppShell';
import { Sleeve } from '../../lib/Sleeve';
import { SortTable } from '../../lib/SortTable';
import { CrispoPaginator, PageResults } from '../../lib/CrispoPaginator';
import { useNavigate } from '@reach/router';
import { CrispoButton } from '../../lib/CrispoButton';
import { useExports } from '../../../hooks/useExports';
import CrispoDatePicker from '../../lib/CrispoDatePicker';
import ScanService from './ScanService';

export const ScansScreen: React.FC = (props) => {
  const today = new Date();
  const startDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  const [scans, setScans] = useState(ScanService.state.query.scans);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [start, setStart] = useState(startDate.getTime());
  const [totalPages, setTotalPages] = useState(100);
  const [sort, setSort] = useState<'username' | 'date'>('date');
  const [direction, setDirection] = useState<-1 | 1>(-1);
  const [totalCount, setTotalCount] = useState(ScanService.state.query.total);
  const [end, setEnd] = useState(today.getTime());
  const { getScanURL } = useExports();
  const navigate = useNavigate();

  const downloadUrl = useMemo(() => {
    return getScanURL(start, end, sort, direction, limit, skip);
  }, [start, end, sort, direction, limit, skip, getScanURL]);

  useEffect(() => {
    ScanService.query(limit, skip * limit, start, end, sort, direction).then((data) => {
      setTotalCount(data.count);
      setTotalPages(Math.ceil(data.count / limit));
      setScans(
        data.scans.map((scan: any) => {
          scan.date = new Date(scan.date).toLocaleString();
          return scan;
        }),
      );
    });
  }, [limit, skip, start, end, direction, sort]);

  return (
    <AppShell>
      <Sleeve title='Scans'>
        <div className='flex items-center mb-3'>
          <div className='flex flex-1'>
            <CrispoDatePicker
              label='From'
              selected={new Date(start)}
              onChange={(date: Date) => {
                setStart(date.getTime());
              }}
            />
            <div className='mx-1'></div>
            <CrispoDatePicker
              label='To'
              selected={new Date(end)}
              onChange={(date: Date) => {
                setEnd(date.getTime());
              }}
            />
          </div>
          <div className='ml-auto'>
            <a download href={downloadUrl}>
              <CrispoButton secondary title='Download' />
            </a>
          </div>
        </div>
        <div className='flex mb-1'>
          <PageResults
            className='ml-auto text-sm'
            options={[10, 25, 50, 100, 500, 1000]}
            activeOption={limit}
            onOptionClick={setLimit}
          />
        </div>
        {scans.length > 0 ? (
          <>
            <SortTable
              direction={direction}
              forceDirection
              sortColumn={sort}
              onItemClick={(i) => {
                navigate(`/users/${i.user_id}`);
              }}
              onHeaderClick={(name, next) => {
                setSort(name.property as any);
                setDirection(next as any);
              }}
              columns={[
                { title: 'Username', property: 'username' },
                { title: 'Date', property: 'date' },
              ]}
              data={scans}
            />

            <div className='items-center justify-center mt-5 mb-5 xl:flex'>
              <span className='flex-1 text-xs text-left'>
                Showing {limit * skip + 1}-{Math.max(limit * skip * 2, limit)} out of {totalCount} results
              </span>
              {totalPages > 1 && (
                <CrispoPaginator pageCount={totalPages} currentPage={skip + 1} onPageChange={(p) => setSkip(p - 1)} />
              )}
            </div>
          </>
        ) : (
          <p>No Scans Found</p>
        )}
      </Sleeve>
    </AppShell>
  );
};
