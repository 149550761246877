import { IUser } from '../../../dto/IUser';
import api from '../../../hooks/useAPI';
import { RewardDto } from '../Rewards/RewardDto';

export enum ContestType {
  ScanDraw = 'scan_draw',
  TicketDraw = 'ticket_draw',
  PhotoSelection = 'photo_selection',
}

export interface WinnerDto {
  username: string;
  date: string;
}

export interface SubmissionDto {
  key: string;
  contest: string;
  user: IUser;
  date: string;
}

export interface ContestDto {
  _id?: string;
  title: string;
  type: ContestType;
  reward: RewardDto;
}

export interface ContestRawDto {
  _id?: string;
  title: string;
  type: ContestType;
  reward: string;
}

const ContestService = {
  cache: { list: [] },
  async list() {
    return api
      .get('/contest')
      .then((res) => res.json())
      .then((data) => {
        this.cache.list = data;
        return data;
      });
  },

  async find(id: string) {
    return api.get(`/contest/${id}`).then((res) => res.json());
  },

  async draw(id: string, from: Date, to: Date) {
    return api.get(`/contest/${id}/draw?from=${from.toISOString()}&to=${to.toISOString()}`).then((res) => res.json());
  },

  async update(id: string, dto: ContestRawDto) {
    return api.put(`/contest/${id}`, dto).then((res) => res.json());
  },

  async pickWinner(contestId: string, uid: string) {
    return api.post(`contest/${contestId}/winners`, { user: uid }).then((res) => res.json());
  },

  async getWinners(contestId: string): Promise<WinnerDto[]> {
    return api.get(`contest/${contestId}/winners`).then((res) => res.json());
  },

  async getSubmissions(contestId: string, from: Date, to: Date): Promise<SubmissionDto[]> {
    return api
      .get(`contest/${contestId}/submissions?from=${from.toISOString()}&to=${to.toISOString()}`)
      .then((res) => res.json());
  },
};

export default ContestService;
