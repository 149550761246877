import React from 'react';
import { CrispoButton } from '../../lib/CrispoButton';
import CrispoCard from '../../lib/CrispoCard';
import { IProduct } from '../Reedeemed/RedeemedService';

interface ProductListItemProps {
  product: IProduct;
  onEdit: (product: IProduct) => void;
  onDelete(id: string): void;
}

export default function ProductListItem(props: ProductListItemProps) {
  return (
    <CrispoCard>
      <div className='flex flex-col items-center h-full w-82'>
        <h1 className='w-full mb-3 text-xl text-center text-crispo'>{props.product.title}</h1>

        <div className='my-auto'>
          <img className='object-contain mb-3' src={props.product.imageUrl} alt='product' />
        </div>

        {props.product.options && props.product.options.length > 0 && (
          <ul className='flex items-center justify-center w-full p-2 my-3 text-center'>
            {props.product.options.map((opt) => (
              <li className='block p-2 mx-1 text-sm bg-gray-200 rounded' key={opt}>
                {opt}
              </li>
            ))}
          </ul>
        )}

        <div className='flex w-full p-2'>
          <CrispoButton block title='Edit' onClick={() => props.onEdit(props.product)} />
          <div className='mx-1'></div>
          <CrispoButton block title='Delete' onClick={() => props.onDelete(props.product._id || '')} />
        </div>
      </div>
    </CrispoCard>
  );
}
