import React, { useEffect, useState, FunctionComponent } from 'react';
import AuthService from '../../hooks/AuthService';
import { LoginScreen } from '../screens/Login/LoginScreen';
import { RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps {
  component: FunctionComponent<any>;
}
export const ProtectedRoute = (props: Props) => {
  const { component: Comp } = props;
  const [authenticated, setAuthenticated] = useState(AuthService.isAuthenticated());
  const [loading, setLoading] = useState(!AuthService.isAuthenticated());

  useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      AuthService.verify()
        .then((valid) => {
          setAuthenticated(valid);
        })
        .catch((err) => {
          console.error(err);
          setAuthenticated(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  function protectedRender() {
    if (authenticated) {
      // if we are authenticated return component
      return <Comp />;
    } else if (loading) {
      // else we are waiting for verify token response
      return <div>loading...</div>;
    } else {
      // token invalid render login screen
      return <LoginScreen />;
    }
  }

  return protectedRender();
};
